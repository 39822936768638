import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  addTagToInterview,
  observeInterviews,
  removeTagFromInterview,
  updateInterviewDisplayName,
} from "../lib/firestore";
import { IInterviewDetail } from "../lib/types";
import { ProjectContext } from "./ProjectContext";
import { UserContext } from "./UserContext";

type InterviewsContextType = {
  interviews: IInterviewDetail[];
  excludeInterviews: IInterviewDetail[];
  setExcludeInterviews: (interviews: IInterviewDetail[]) => void;
  includeInterviewer: boolean;
  setIncludeInterviewer: (show: boolean) => void;
  addTag: (id: string, tag: string) => void;
  removeTag: (id: string, tag: string) => void;
  updateName: (id: string, name: string) => void;
};

export const InterviewsContext = React.createContext<InterviewsContextType>({
  interviews: [],
  excludeInterviews: [],
  setExcludeInterviews: () => {},
  includeInterviewer: false,
  setIncludeInterviewer: () => {},
  addTag: () => {},
  removeTag: () => {},
  updateName: () => {},
});

export function InterviewsContextProvider(props: React.PropsWithChildren<unknown>) {
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const [interviews, setInterviews] = useState<IInterviewDetail[]>([]);
  const [excludeInterviews, _setExcludeInterviews] = useState<IInterviewDetail[]>([]);
  const setExcludeInterviews = useCallback(
    (interviews: IInterviewDetail[]) => {
      _setExcludeInterviews(interviews);
    },
    [_setExcludeInterviews]
  );
  const [includeInterviewer, setIncludeInterviewer] = useState<boolean>(false);

  const addTag = useCallback(
    (id: string, tag: string) => {
      if (project === null || user === null) return;
      addTagToInterview(project.id, user.uid, id, tag).catch(console.error);
    },
    [project, user]
  );

  const removeTag = useCallback(
    (id: string, tag: string) => {
      if (project === null || user === null) return;
      removeTagFromInterview(project.id, user.uid, id, tag).catch(console.error);
    },
    [project, user]
  );

  const updateName = useCallback((id: string, name: string) => {
    if (project === null || user === null) return;
    updateInterviewDisplayName(project.id, user.uid, id, name).catch(console.error);
  }, [project, user]);

  useEffect(() => {
    if (project === null || user === null) return;
    let unsubscribe: () => void = () =>
    console.error(
      "Unsubscriber function was not registered: InterviewsContext"
    );
    observeInterviews(project.id, user.uid, setInterviews).then(u => unsubscribe = u);
    return () => unsubscribe();
  }, [project, user]);

  const contextValue = {
    interviews: interviews,
    excludeInterviews,
    setExcludeInterviews,
    includeInterviewer,
    setIncludeInterviewer,
    addTag,
    removeTag,
    updateName,
  };
  return (
    <InterviewsContext.Provider value={contextValue}>
      {props.children}
    </InterviewsContext.Provider>
  );
}
