import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  observeGeneratedTopics,
  observeUserTopics,
  updateTopic,
} from "../lib/firestore";
import { IGeneratedTopic, ITopic } from "../lib/types";
import { ProjectContext } from "./ProjectContext";
import { UserContext } from "./UserContext";

type TopicsContextType = {
  generatedTopics: IGeneratedTopic[];
  topics: ITopic[];
  updateLabel: (topic: ITopic, label: string) => void;
};

export const TopicsContext = React.createContext<TopicsContextType>({
  topics: [],
  generatedTopics: [],
  updateLabel: () => {},
});

export function TopicsContextProvider(props: React.PropsWithChildren<any>) {
  const { uid } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [generatedTopics, setGeneratedTopics] = useState<IGeneratedTopic[]>([]);
  const updateLabel = useCallback(
    (topic: ITopic, name: string) => {
      if (project === null || uid === null || topic.topicId == null) return;
      updateTopic(project.id, uid, topic, name);
      setTopics((topics) => {
        const i = topics.findIndex((t) => t.topicId === topic.topicId);
        if (i < 0) return topics;
        const newTopics = [...topics];
        newTopics.splice(i, 1, { ...topic, name: name });
        return newTopics;
      });
    },
    [project, uid, setTopics]
  );

  useEffect(() => {
    if (project == null || uid === null) return;
    let unsubscribeUserTopics: () => void = () => console.error("Unsubscriber function was not registered: TopicsContext 1")
    let unsubscribeGenTopics: () => void = () => console.error("Unsubscriber function was not registered: TopicsContext 2")
    observeUserTopics(project.id, uid, setTopics)
      .then((u) => unsubscribeUserTopics = u)
      .catch(console.error);
    observeGeneratedTopics(project.id, uid, setGeneratedTopics)
      .then((u) => unsubscribeGenTopics = u)
      .catch(console.error);
    return () => {
      unsubscribeUserTopics();
      unsubscribeGenTopics();
    };
  }, [project, uid]);

  const ctx = {
    topics,
    generatedTopics,
    updateLabel,
  };

  return (
    <TopicsContext.Provider value={ctx}>
      {props.children}
    </TopicsContext.Provider>
  );
}
