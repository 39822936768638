import { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  ProjectsContext,
  ProjectWithStatusRecord,
} from "../contexts/ProjectsContext";
import { useHistory } from "react-router";
import styles from "./Projects.module.scss";
import { UserContext } from "../contexts/UserContext";
import {
  Status,
  StatusRecord,
} from "../lib/status";
import { statusRecordLabel } from "../contexts/ProjectStatusContext";

export default function Projects() {
  const { user, logout } = useContext(UserContext);
  const { projects: __projects, createProject } = useContext(ProjectsContext);
  const sortedProjects = sortProjects(__projects, user?.projectsTouched);
  const [newProjectName, setNewProjectName] = useState<string>("");
  const [isCreatingProject, setCreatingProject] = useState<boolean>(false);
  const history = useHistory();

  const onCreateProject = useCallback(() => {
    if (newProjectName.length <= 0) return;
    setCreatingProject(true);
    createProject(newProjectName).then((projectId) => {
      setCreatingProject(false);
      history.push(`/projects/${projectId}`);
    });
  }, [history, createProject, newProjectName]);

  return (
    <div className={styles.projectsRoot}>
      <div className={styles.mainNav}>
        <div className={styles.logo} />
      </div>
      {sortedProjects === null || sortedProjects.length === 0 ? (
        <EmptyProjects projects={sortedProjects} />
      ) : (
        <div className={styles.projectsList}>
          <BackgroundLayer />
          <h1>Projects</h1>
          <div className={styles.scrollingContainer}>
            <div className={styles.projects}>
              {sortedProjects?.map((p) => (
                <Project key={p.project.id} {...p} />
              )) ?? null}
            </div>
          </div>
        </div>
      )}
      <div className={styles.createProjectForm}>
        <h1>Create a new research project</h1>
        <div className={`subhead ${styles.projectName}`}>Project name</div>
        <input
          type="text"
          placeholder="Project name"
          defaultValue={newProjectName}
          onChange={(e) => setNewProjectName(e.currentTarget.value)}
        />
        <button
          className="button primary"
          disabled={newProjectName.length === 0}
          onClick={onCreateProject}
        >
          Create Project
        </button>
        <button className={`linkButton ${styles.signOutLink}`} onClick={logout}>
          Sign out
        </button>
      </div>
      <div className={`loading-layer${isCreatingProject ? " active" : ""}`}>
        <div className="loading-indicator" />
      </div>
    </div>
  );
}

function Project(props: ProjectWithStatusRecord) {
  const isReady = isProjectReady(props.statusRecord);
  const numTopics = props.project.topics_count ?? 0;
  const numInterviews = props.project.interviews_count ?? 0;

  return (
    <div className={styles.projectCard}>
      <div className={styles.projectHead}>
        <h2>{props.project.displayName}</h2>
        <Link
          className={`button secondary ${styles.viewProjectButton}`}
          to={`/projects/${props.project.id}`}
        >
          View Project
        </Link>
      </div>
      <div className={styles.projectStatus}>
        {isReady ? (
          <div className={styles.projectStatusText}>
            <span
              className={`subhead ${styles.numTopics}`}
            >{`${numTopics} topic${numTopics > 1 ? "s" : ""}`}</span>
            <span
              className={`subhead ${styles.numInterviews}`}
            >{`${numInterviews} interview${
              numInterviews > 1 ? "s" : ""
            }`}</span>
          </div>
        ) : (
          <div className={`text-italic ${styles.progressIndicator}`}>
            {statusRecordLabel(props.statusRecord)}
          </div>
        )}
      </div>
    </div>
  );
}

function EmptyProjects(props: { projects: ProjectWithStatusRecord[] | null }) {
  return (
    <div
      className={`${styles.emptyProjects}${
        props.projects === null ? ` ${styles.loadingProjects}` : ""
      }`}
    >
      <BackgroundLayer />
      <div className={styles.headings}>
        <h1 className={styles.large}>From interviews to insights, faster.</h1>
        <h2 className={styles.large}>
          Qually's algorithm finds relevant topics from across your research to
          help you make sense of it all.
        </h2>
      </div>
    </div>
  );
}

function BackgroundLayer() {
  return (
    <div className={styles.backgroundLayer}>
      <div className={`${styles.object} ${styles.group167}`} />
      <div className={`${styles.object} ${styles.ellipse195}`} />
      <div className={`${styles.object} ${styles.ellipse201}`} />
      <div className={`${styles.object} ${styles.ellipse200}`} />
      <div className={`${styles.object} ${styles.rectangle173}`} />
      <div className={`${styles.object} ${styles.ellipse206}`} />
      <div className={`${styles.object} ${styles.rectangle179}`} />
      <div className={`${styles.object} ${styles.ellipse214}`} />
    </div>
  );
}

function sortProjects(
  projects: ProjectWithStatusRecord[] | null,
  projectsTouched?: string[]
): ProjectWithStatusRecord[] {
  if (projects == null) return [];
  if (projectsTouched == null) return projects;

  const projs = [...projects];
  const ret: ProjectWithStatusRecord[] = [];
  projectsTouched.forEach((id) => {
    const index = projs.findIndex((p) => p.project.id === id);
    if (index >= 0) {
      ret.push(projs[index]);
      projs.splice(index, 1);
    }
  });
  ret.push(...projs);
  return ret;
}

function isProjectReady(statusRecord: StatusRecord | undefined): boolean {
  // TODO: these are perhaps legacy records?
  if (statusRecord === undefined) return true;

  return statusRecord.sortTopQuotes.status === Status.Complete;
}
