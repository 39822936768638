import { ChangeEvent, useCallback, useContext, useState } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { IBoxFolder, IInterviewTheme, IProject } from "../lib/types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import styles from "./ProjectSettings.module.scss";
import { ToastContext } from "../contexts/ToastContext";
import { ProjectsContext } from "../contexts/ProjectsContext";
import useModalBoxFolderPicker from "../components/useModalBoxFolderPicker";
import { ProjectStatusContext, statusRecordLabel } from "../contexts/ProjectStatusContext";
import { SettingsState, settingsState, Status } from "../lib/status";
import useModalInterviewGuideEditor from "../components/InterviewGuideEditor";

export default function ProjectSettings(props: { className: string }) {
  const history = useHistory();
  const { project, startAnalyzeProject } = useContext(ProjectContext);
  const { deleteProject } = useContext(ProjectsContext);
  const statusRecord = useContext(ProjectStatusContext);
  const [isDeletingProject, setDeletingProject] = useState<boolean>(false);
  const onDeleteProject = useCallback(() => {
    if (project == null) return;
    setDeletingProject(true);
    deleteProject(project.id).then((_) => {
      setDeletingProject(false);
      history.replace("/projects");
    });
  }, [project, deleteProject, history]);
  const [preparingAnalysis, setPreparingAnalysis] = useState<boolean>(false);
  const onRerunAnalysis = useCallback(() => {
    setPreparingAnalysis(true);
    startAnalyzeProject().then(() => setPreparingAnalysis(false));
  }, [startAnalyzeProject]);

  if (project === null) return null;

  const state = settingsState(project, statusRecord);

  return (
    <div className={props.className}>
      {state === SettingsState.ANALYZING || state === SettingsState.READY ? (
        <div className={styles.subnav}>
          <h1 className={styles.heading}>Project Settings</h1>
        </div>
      ) : null}
      <div className={styles.scrollingContainer}>
        {state === SettingsState.ANALYZING || state === SettingsState.READY ? (
          <>
            <div className={styles.content}>
              <div className={styles.assetsContainer}>
                <h2>Project Assets</h2>
                <SettingsAssets />
                <Link
                  to={`/projects/${project.id}/interviews`}
                  className={`linkButton primary ${styles.seeTranscriptionsLink}`}
                >
                  See transcriptions
                </Link>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.analysisContainer}>
                <h2>Project Analysis</h2>
                {state === SettingsState.ANALYZING ? (
                  <div className={`text-italic ${styles.progressIndicator}`}>
                    {statusRecordLabel(statusRecord)}
                  </div>
                ) : (
                  <button
                    className="button primary"
                    disabled={preparingAnalysis}
                    onClick={onRerunAnalysis}
                  >
                    Re-run analysis
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.content}>
            <div className={styles.stepsContainer}>
              <h2>Project Set-Up Checklist</h2>
              <SettingSteps />
            </div>
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.detailsContainer}>
            <h2>Project Details</h2>
            <ProjectDetailsForm />
          </div>
          {project.neverDelete ?? false ? null : (
            <div className={styles.dangerZoneContainer}>
              <button
                className="button secondary alert"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this project?"
                    )
                  ) {
                    onDeleteProject();
                  }
                }}
              >
                Delete Project
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={`loading-layer${isDeletingProject ? " active" : ""}`}>
        <div className="loading-indicator" />
      </div>
    </div>
  );
}

function SettingSteps() {
  return (
    <div className={styles.steps}>
      <SettingStep1 />
      <SettingStep2 />
      <SettingStep3 />
    </div>
  );
}

function SettingStep1() {
  const { project, updateProject } = useContext(ProjectContext);
  const onFolderSelected = useCallback(
    (folder: IBoxFolder) => {
      if (project === null) {
        console.error(
          "Project id was missing. Skips adding box folder to the project."
        );
        return;
      }
      const updatedProject = { ...project };
      updatedProject.boxFolder = folder;
      updateProject(updatedProject);
    },
    [project, updateProject]
  );

  const statusRecord = useContext(ProjectStatusContext);
  const selectFolder = useModalBoxFolderPicker(onFolderSelected);
  if (project === null) return null;

  const state = settingsState(project, statusRecord);
  const isTranscribing =
    (statusRecord?.transcriptImport?.status ?? Status.NotStarted) !==
    Status.Complete;
  const numHasTranscribed = project?.interviews_count ?? 0;

  return (
    <div
      className={`${styles.step} ${
        state === SettingsState.NEEDS_FOLDER ? styles.active : styles.finished
      }`}
    >
      <h3 className={styles.bulletItem}>
        <span className={`iconLabel ${styles.bulletNumber}`}>
          {state === SettingsState.NEEDS_FOLDER ? 1 : ""}
        </span>
        <span className={styles.bulletCaption}>
          Import interview recordings from Box
        </span>
      </h3>
      {state === SettingsState.NEEDS_FOLDER ? (
        <div className={`${styles.bulletContent} ${styles.withBorder}`}>
          <div className="text">
            Select the Box folder containing your interview recordings. Qually
            will automatically transcribe them.
          </div>
          <button
            className={`button primary ${styles.selectBoxFolderButton}`}
            onClick={selectFolder}
          >
            Select Box Folder
          </button>
        </div>
      ) : (
        <div className={`${styles.bulletContent} ${styles.withBorder}`}>
          <div>
            <div className={`smallLabel ${styles.selectedBoxFolder}`}>
              {project?.boxFolder?.name ?? "(folder name missing??)"}
            </div>
            <button
              className={`linkButton primary ${styles.selectBoxFolderLink}`}
              onClick={selectFolder}
            >
              Choose a different folder
            </button>
          </div>
          {isTranscribing ? (
            <div className={`text-italic ${styles.progressIndicator}`}>
              Transcribing
            </div>
          ) : numHasTranscribed > 0 ? (
            <div className={`text ${styles.processingDone}`}>
              {`${numHasTranscribed} recording${
                numHasTranscribed > 1 ? "s" : ""
              } transcribed`}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

function SettingStep2() {
  const { project, updateProject } = useContext(ProjectContext);
  const statusRecord = useContext(ProjectStatusContext);
  const onEditInterviewGuide = useInterviewGuideEditor(project, updateProject);

  const state = settingsState(project, statusRecord);
  const isExtractingKeywords =
    (statusRecord?.matchGuideToTranscript?.status ?? Status.NotStarted) !==
    Status.Complete;

  const isProcessingDone =
    state > SettingsState.NEEDS_INTERVIEW_GUIDE && !isExtractingKeywords;

  return (
    <div
      className={`${styles.step} ${
        state === SettingsState.NEEDS_INTERVIEW_GUIDE
          ? styles.active
          : state === SettingsState.NEEDS_FINISH_UP
          ? styles.finished
          : ""
      }`}
    >
      <h3 className={styles.bulletItem}>
        <span className={`iconLabel ${styles.bulletNumber}`}>
          {state === SettingsState.NEEDS_FINISH_UP ? "" : 2}
        </span>
        <span className={styles.bulletCaption}>Add your interview guide</span>
      </h3>
      {state === SettingsState.NEEDS_INTERVIEW_GUIDE ? (
        <div className={`${styles.bulletContent} ${styles.withBorder}`}>
          <div className="text">
            Qually uses keywords and concepts from your interview guide to find
            relevant topics across your interviews.
          </div>
          <button
            className={`button primary ${styles.editInterviewGuideButton}`}
            onClick={onEditInterviewGuide}
          >
            Submit Interview Guide
          </button>
        </div>
      ) : state === SettingsState.NEEDS_FINISH_UP ? (
        <div className={`${styles.bulletContent} ${styles.withBorder}`}>
          <div>
            <div className={`smallLabel ${styles.storedInterviewGuideText}`}>
              Interview Guide
            </div>
            <button
              className={`linkButton primary ${styles.editInterviewGuideTextLink}`}
              onClick={onEditInterviewGuide}
            >
              Edit
            </button>
          </div>
          {isExtractingKeywords ? (
            <div className={`text-italic ${styles.progressIndicator}`}>
              Extracting keywords
            </div>
          ) : isProcessingDone ? (
            <div className={`text ${styles.processingDone}`}>
              Processing complete
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

function SettingStep3() {
  const { project, updateProject, startAnalyzeProject } =
    useContext(ProjectContext);
  const toaster = useContext(ToastContext);
  const statusRecord = useContext(ProjectStatusContext);

  const onFinishUp = useCallback(() => {
    if (project === null) return;
    updateProject({ id: project.id, doneSetup: true })
      .then((_) => toaster.showToast("Project set-up complete!"))
      .then((_) => startAnalyzeProject());
  }, [project, updateProject, startAnalyzeProject, toaster]);

  if (project === null) return null;

  const state = settingsState(project, statusRecord);

  return (
    <div
      className={`${styles.step} ${
        state === SettingsState.NEEDS_FINISH_UP ? styles.active : ""
      }`}
    >
      <h3 className={styles.bulletItem}>
        <span className={`iconLabel ${styles.bulletNumber}`}>3</span>
        <span className={styles.bulletCaption}>Finish set-up</span>
      </h3>
      <div className={styles.bulletContent}>
        <div className="text">
          Once your recordings and interview guide are processed, Qually will
          automatically begin analyzing your data.
        </div>
        <button
          className={`button primary ${styles.finishSetupButton}`}
          disabled={state !== SettingsState.NEEDS_FINISH_UP}
          onClick={onFinishUp}
        >
          Finish Set-Up
        </button>
      </div>
    </div>
  );
}

/* * * * * * * * */

function ProjectDetailsForm() {
  const { project, updateProject } = useContext(ProjectContext);
  const [name, setName] = useState<string>(project?.displayName ?? "");

  const onChangeName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.currentTarget.value);
      if (project === null) return;
      if (project.displayName !== e.currentTarget.value) {
        const updatedProject = { ...project };
        updatedProject.displayName = e.currentTarget.value;
        updateProject(updatedProject);
      }
    },
    [project, updateProject]
  );

  if (project === null) return null;

  return (
    <div className={styles.details}>
      <div className={`subhead ${styles.formCaption}`}>Project name</div>
      <input
        type="text"
        className={styles.input}
        defaultValue={name}
        onChange={onChangeName}
      />
    </div>
  );
}

/* * * * * * * * */

function SettingsAssets() {
  const { project, updateProject } = useContext(ProjectContext);
  const statusRecord = useContext(ProjectStatusContext);
  const onFolderSelected = useCallback(
    (folder: any) => {
      if (project === null) return;
      const updatedProject = { ...project };
      updatedProject.boxFolder = folder;
      updateProject(updatedProject);
    },
    [project, updateProject]
  );
  const selectFolder = useModalBoxFolderPicker(onFolderSelected);
  const onEditInterviewGuide = useInterviewGuideEditor(project, updateProject);

  const isTranscribing =
    (statusRecord?.transcriptImport?.status ?? Status.NotStarted) !==
    Status.Complete;
  const numHasTranscribed = project?.interviews_count ?? 0;

  const isExtractingKeywords =
    (statusRecord?.matchGuideToTranscript?.status ?? Status.NotStarted) !==
    Status.Complete;

  return (
    <div className={styles.assets}>
      <div className={`${styles.asset} ${styles.interviewRecordings}`}>
        <div>
          <div className={`subhead ${styles.assetHeading}`}>
            Interview Recordings
          </div>
          <div className={`smallLabel ${styles.selectedBoxFolder}`}>
            {project?.boxFolder?.name ?? "(folder name missing??)"}
          </div>
          {isTranscribing ? (
            <div className={`text-italic ${styles.progressIndicator}`}>
              Transcribing
            </div>
          ) : numHasTranscribed > 0 ? (
            <div className={`text ${styles.processingDone}`}>
              {`${numHasTranscribed} recording${
                numHasTranscribed > 1 ? "s" : ""
              } transcribed`}
            </div>
          ) : null}
        </div>
        <button
          className={`linkButton primary ${styles.selectBoxFolderLink}`}
          onClick={selectFolder}
        >
          Choose a different folder
        </button>
      </div>
      <div className={`${styles.asset} ${styles.interviewGuideText}`}>
        <div>
          <div className={`subhead ${styles.assetHeading}`}>
            Interview Guide
          </div>
          <div className={`smallLabel ${styles.storedInterviewGuideText}`}>
            Interview Guide
          </div>
          {isExtractingKeywords ? (
            <div className={`text-italic ${styles.progressIndicator}`}>
              Extracting keywords
            </div>
          ) : (
            <div className={`text ${styles.processingDone}`}>
              Processing complete
            </div>
          )}
        </div>
        <button
          className={`linkButton primary ${styles.editInterviewGuideTextLink}`}
          onClick={onEditInterviewGuide}
        >
          Edit
        </button>
      </div>
    </div>
  );
}

function useInterviewGuideEditor(
  project: IProject | null,
  updateProject: (project: IProject) => void
) {
  const onSaveInterviewGuide = useCallback(
    (guide: IInterviewTheme[]) => {
      if (project === null) return;
      const updatedProject = { ...project };
      updatedProject.interviewGuide = guide;
      updateProject(updatedProject);
    },
    [project, updateProject]
  );
  const showModalEditor = useModalInterviewGuideEditor(onSaveInterviewGuide);

  const onEditInterviewGuide = useCallback(() => {
    if (project == null) return;
    showModalEditor(project.interviewGuide);
  }, [project, showModalEditor]);

  return onEditInterviewGuide;
}
