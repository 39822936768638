import panelStyles from "./Panel.module.scss";
import LeftNav from "../components/LeftNav";
import MainNav from "../components/MainNav";
import Topics from "./Topics";

export default function TopicsPanel() {

  return (
    <div className={panelStyles.twoColumnsPanel}>
      <MainNav className={panelStyles.mainNav} />
      <LeftNav className={panelStyles.leftColumn} />
      <Topics className={panelStyles.mainColumn} />
    </div>
  );
}
