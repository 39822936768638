import { useRef, useEffect, useState, useCallback, useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { UserContext } from "../contexts/UserContext";
import { getInterview } from "../lib/firestore";
import { IInterview, INTERVIEWER, IInterviewDetail, ISegment } from "../lib/types";
import styles from "./Transcript.module.scss";

const EMPTY_INTERVIEW: IInterviewDetail = {
  id: "",
  ivid: "",
  displayName: "",
  transcript: [],
  tags: {},
};

export default function Transcript(props: {
  interview?: IInterviewDetail | null;
  selectedSegment?: ISegment;
  className?: string;
}) {
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const [interview, setInterview] = useState<IInterview>(EMPTY_INTERVIEW);

  const ref = useRef<HTMLDivElement>(null);

  const scrollToSegment = useCallback(() => {
    if (ref.current == null) return;
    if (props.selectedSegment?.indexInInterview == null) return;
    const selectedItem: HTMLLIElement | null = ref.current.querySelector(
      `ul li[data-index="${props.selectedSegment?.indexInInterview}"]`
    );
    const parent = ref.current.querySelector("ul")?.parentElement;
    let y;
    if (selectedItem == null || parent == null) {
      y = 0;
    } else {
      y = selectedItem.offsetTop - parent.offsetHeight / 2;
    }
    ref.current.scrollTo({
      top: y < 0 ? 0 : y,
      left: 0,
      behavior: "smooth",
    });
  }, [ref, props.selectedSegment]);

  useEffect(() => {
    if (
      project === null ||
      user === null ||
      props.selectedSegment == null ||
      props.selectedSegment.interview == null
    )
      return;
    getInterview(project.id, user.uid, props.selectedSegment.interview)
      .then(setInterview)
      .then(() => requestAnimationFrame(scrollToSegment));
  }, [project, user, props.interview, props.selectedSegment, scrollToSegment]);

  useEffect(() => {
    if (project === null || user === null || props.interview == null) return;
    getInterview(project.id, user.uid, props.interview.id).then(setInterview);
  }, [project, user, props.interview, setInterview]);

  if (interview == null) {
    return (
      <div className={`${styles.emptyTranscript} ${props.className}`}>
        <h4>Select a quote to see it in the full transcript</h4>
      </div>
    );
  }

  return (
    <div className={`${styles.transcript} ${props.className}`} ref={ref}>
      <div className={styles.transcriptHeading}>
        <span className="text">Transcript:</span>
        <span className="subhead">{interview.displayName}</span>
      </div>
      <ul className={styles.segments}>
        {interview.transcript.map((t, i) => (
          <li key={i} data-index={i} className={styles.segment}>
            <div className="subhead">
              {t.interviewer ?? false ? INTERVIEWER.displayName : interview.displayName}
            </div>
            <div
              className={`text ${styles.text} ${
                props.selectedSegment?.indexInInterview === i
                  ? styles.selected
                  : ""
              }`}
            >
              {t.text}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
