import { useEffect, useContext, useState, useCallback, useMemo } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { TopicsContext } from "../contexts/TopicsContext";
import { UserContext } from "../contexts/UserContext";
import {
  addFavorite,
  removeFavorite,
  getFavorites,
  removeTopicFromSegment,
  addTopicToSegment,
} from "../lib/firestore";
import {
  ITopic,
  ISegment,
  ISegmentWithFav,
  IInterviewDetail,
  INTERVIEWER,
  ISegmentWithDisplayName,
} from "../lib/types";
import styles from "./Favorites.module.scss";
import segmentStyles from "../components/Segment.module.scss";
import { SegmentTopics } from "../components/Segment";
import InView from "react-intersection-observer";
import star48 from "../images/star-fill-gray5-48.svg";
import star24 from "../images/star-fill-gray5-24.svg";
import star16 from "../images/star-fill-gray5-16.svg";
import { InterviewsContext } from "../contexts/InterviewsContext";

enum CopyingState {
  Idle,
  Copying,
  Copied,
}

type FavoritesByTopics = {
  topicId: string;
  topicLabel: string;
  segments: ISegmentWithFav[];
};

type ISegmentWithTopic = ISegment & { topic: string };

function useFavorites() {
  const undoHandles: { [key: string]: NodeJS.Timeout } = useMemo(
    () => ({}),
    []
  );
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const { topics } = useContext(TopicsContext);
  const { interviews } = useContext(InterviewsContext);
  const [favorites, setFavorites] = useState<FavoritesByTopics[]>([]);

  useEffect(() => {
    if (user === null) return;
    if (project === null) return;
    if (topics.length === 0) return;
    const topicMap = topics.reduce<{ [k: string]: ITopic }>((c, v) => {
      c[`${v.topicId}`] = v;
      return c;
    }, {});

    getFavorites(project.id, user.uid)
      .then((segments) =>
        segments.reduce<FavoritesByTopics[]>((c, v) => {
          const seg = {
            ...v,
            fav: true,
            displayName: speakerName(v, interviews),
          };
          Array.from(
            new Set([...v.topicIds, ...(v.user_topicIds ?? [])])
          ).forEach((topicId) => {
            const fbt = c.find((x) => x.topicId === topicId);
            if (fbt === undefined) {
              c.push({
                topicId: topicId,
                topicLabel: topicMap[`${topicId}`].name,
                segments: [seg],
              });
            } else {
              fbt.segments.push(seg);
            }
          });
          return c;
        }, [])
      )
      .then(setFavorites);
  }, [user, project, topics, interviews]);

  const permanentlyDeleteFavorite = useCallback(
    (favoritesKey: number, segmentsKey: number, id: string) => {
      delete undoHandles[`${favoritesKey}-${segmentsKey}`];
      const favs = [...favorites];
      favs.forEach((fav) => {
        fav.segments = fav.segments.filter((s) => s.id !== id);
      });
      setFavorites(favs);
    },
    [favorites, undoHandles]
  );

  const toggleFavorite = (
    favoritesKey: number,
    segmentsKey: number,
    fav: boolean
  ) => {
    if (user === null || project === null) return;

    const favs = [...favorites];
    const segment = favs[favoritesKey].segments[segmentsKey];

    if (fav) {
      addFavorite(project.id, user.uid, segment);
      clearTimeout(undoHandles[`${favoritesKey}-${segmentsKey}`]);
      delete undoHandles[`${favoritesKey}-${segmentsKey}`];
    } else {
      removeFavorite(project.id, user.uid, segment);
      undoHandles[`${favoritesKey}-${segmentsKey}`] = setTimeout(() => {
        permanentlyDeleteFavorite(favoritesKey, segmentsKey, segment.id);
      }, 2000);
    }

    favs[favoritesKey].segments.splice(segmentsKey, 1, {
      ...segment,
      fav: fav,
    });
    setFavorites(favs);
  };

  const removeTopic = useCallback(
    async (
      favoritesKey: number,
      segmentsKey: number,
      segment: ISegmentWithFav,
      topicId: string
    ) => {
      if (project === null || user === null) return;
      const updatedSegment = await removeTopicFromSegment(
        project.id,
        user.uid,
        segment.id,
        topicId
      );
      const newSegment: ISegmentWithFav = {
        ...updatedSegment,
        fav: segment.fav ?? false,
        displayName: segment.displayName,
      };
      setFavorites((favorites) => {
        const newFavorites = [...favorites];
        newFavorites[favoritesKey].segments.splice(segmentsKey, 1, newSegment);
        return newFavorites;
      });
    },
    [project, user, setFavorites]
  );

  const addTopic = useCallback(
    async (
      favoritesKey: number,
      segmentsKey: number,
      segment: ISegmentWithFav,
      topicId: string
    ) => {
      if (project === null || user === null) return;
      const updatedSegment = await addTopicToSegment(
        project.id,
        user.uid,
        segment.id,
        topicId
      );
      const newSegment: ISegmentWithFav = {
        ...updatedSegment,
        fav: segment.fav ?? false,
        displayName: segment.displayName,
      };
      setFavorites((favorites) => {
        const newFavorites = [...favorites];
        newFavorites[favoritesKey].segments.splice(segmentsKey, 1, newSegment);
        return newFavorites;
      });
    },
    [project, user, setFavorites]
  );

  return [user, favorites, toggleFavorite, removeTopic, addTopic] as const;
}

export default function Favorites(props: { className?: string }) {
  const { interviews } = useContext(InterviewsContext);
  const [user, favorites, toggleFavorite, removeTopic, addTopic] =
    useFavorites();
  const [copying, setCopying] = useState<CopyingState>(CopyingState.Idle);

  const onCopyFavorites = useCallback(() => {
    if (user == null) return;
    setCopying(CopyingState.Copying);
    const favs = favorites.reduce<ISegmentWithTopic[]>((c, v) => {
      c.push(
        ...v.segments
          .filter((s) => s.id !== user.uid)
          .map((s) => ({ ...s, topic: v.topicLabel }))
      );
      return c;
    }, []);
    const texts = favs.reduce<string>((c, v) => {
      c += segmentToText(v, interviews);
      return c;
    }, "");

    window.navigator.clipboard.writeText(texts).then(
      (_) => {
        setCopying(CopyingState.Copied);
        setTimeout(() => {
          setCopying(CopyingState.Idle);
        }, 2000);
      },
      () => {
        window.alert(
          "Something went wrong. Quotes are not copied to the clipboard."
        );
        setCopying(CopyingState.Idle);
      }
    );
  }, [user, favorites, interviews]);

  const [isScrolled, setScrolled] = useState<boolean>(false);

  return (
    <div className={props.className}>
      <SubNav
        onCopy={onCopyFavorites}
        copyingState={copying}
        isScrolled={favorites.length > 0 && isScrolled}
        isEmpty={favorites.length === 0}
      />
      <Segments
        favorites={favorites}
        toggleFavorite={toggleFavorite}
        removeTopic={removeTopic}
        addTopic={addTopic}
        onScroll={setScrolled}
      />
    </div>
  );
}

function SubNav(props: {
  onCopy: () => void;
  copyingState: CopyingState;
  isScrolled: boolean;
  isEmpty: boolean;
}) {
  const additionalButtonClassName =
    props.copyingState === CopyingState.Copied ? ` success` : "";

  return (
    <div
      className={`${styles.subnav}${
        props.isScrolled ? ` ${styles.withShadow}` : ""
      }`}
    >
      <h1 className={styles.heading}>Favorites</h1>
      <div className={styles.controls}>
        <button
          className={`button primary ${styles.copyClipboardButton}${additionalButtonClassName}`}
          onClick={props.onCopy}
          disabled={
            props.isEmpty || props.copyingState === CopyingState.Copying
          }
        >
          {props.copyingState === CopyingState.Copied
            ? "Copied to Clipboard!"
            : "Copy All to Clipboard"}
        </button>
      </div>
    </div>
  );
}

function Segments(props: {
  favorites: FavoritesByTopics[];
  toggleFavorite: (
    favoritesKey: number,
    segmentsKey: number,
    fav: boolean
  ) => void;
  removeTopic: (
    favoritesKey: number,
    segmentsKey: number,
    segment: ISegmentWithFav,
    topicId: string
  ) => void;
  addTopic: (
    favoritesKey: number,
    segmentsKey: number,
    segment: ISegmentWithFav,
    topicId: string
  ) => void;
  onScroll: (b: boolean) => void;
}) {
  const { favorites, toggleFavorite, removeTopic, addTopic } = props;

  if (favorites.length === 0) {
    return <EmptyFavorites />;
  }

  return (
    <div className={styles.scrollingContainer}>
      <InView
        as="div"
        className={styles.intersection}
        onChange={(inView) => props.onScroll(!inView)}
      >
        &nbsp;
      </InView>
      <div className={styles.favorites}>
        {favorites.map((favorite, i) => (
          <div key={i} className={styles.topicContainer}>
            <h3 className={styles.topicLabel}>
              <span>{favorite.topicLabel}</span>
              <span className={`numberBadge ${styles.numFavorites}`}>
                {favorite.segments.length}
              </span>
            </h3>
            <div className={styles.segments}>
              {favorite.segments.map((segment, j) =>
                segment.fav ? (
                  <Segment
                    key={j}
                    segment={segment}
                    onUnfavorite={() => toggleFavorite(i, j, false)}
                    onRemoveTopic={(topicId: string) =>
                      removeTopic(i, j, segment, topicId)
                    }
                    onAddTopic={(topicId: string) =>
                      addTopic(i, j, segment, topicId)
                    }
                  />
                ) : (
                  <RemovedSegment
                    key={j}
                    onUndo={() => toggleFavorite(i, j, true)}
                  />
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Segment(props: {
  segment: ISegmentWithDisplayName;
  onUnfavorite: () => void;
  onRemoveTopic: (topicId: string) => void;
  onAddTopic: (topicId: string) => void;
}) {
  const spans = Math.ceil(props.segment.text.length / 128);

  const [isActive, setActive] = useState<boolean>(false);

  return (
    <div
      className={`${segmentStyles.segment}${
        isActive ? ` ${segmentStyles.active}` : ""
      }`}
      style={{ gridRowEnd: `span ${spans}` }}
    >
      <div className={`subhead ${segmentStyles.speaker}`}>
        {props.segment.displayName}
      </div>
      <div className={`text ${segmentStyles.text}`}>{props.segment.text}</div>
      <SegmentTopics
        segment={props.segment}
        onRemoveTopic={props.onRemoveTopic}
        toggleTopicList={setActive}
        addTopic={(topic) => {
          setActive(false);
          props.onAddTopic(topic.topicId);
        }}
      />
      <button
        className={`${segmentStyles.star} ${segmentStyles.favorite}`}
        onClick={props.onUnfavorite}
      />
    </div>
  );
}

function RemovedSegment(props: { onUndo: () => void }) {
  return (
    <div className={`${segmentStyles.segment} ${styles.removedSegment}`}>
      <span className={`text ${styles.removedText}`}>
        Removed from favorites
      </span>
      <button className="linkButton" onClick={props.onUndo}>
        Undo
      </button>
    </div>
  );
}

function EmptyFavorites() {
  return (
    <div className={styles.emptyFavorites}>
      <div className={styles.stars}>
        <img src={star16} className={`${styles.star16}`} alt="Star 16" />
        <img src={star24} className={`${styles.star24}`} alt="Star 24" />
        <img src={star48} className={`${styles.star48}`} alt="Star 48" />
      </div>
      <h2>No Favorites Yet</h2>
      <h4>
        Click the star at the top of any quote to add it to your favorites.
      </h4>
    </div>
  );
}

function speakerName(segment: ISegment, interviews: IInterviewDetail[]) {
  const interviewIndex = interviews.findIndex(
    (p) => p.id === segment.interview
  );
  return segment.interviewer ?? false
    ? INTERVIEWER.displayName
    : interviewIndex < 0
    ? ""
    : interviews[interviewIndex].displayName;
}

function segmentToText(
  segment: ISegmentWithTopic,
  interviews: IInterviewDetail[]
) {
  return [
    speakerName(segment, interviews),
    segment.text,
    `[${segment.topic}]`,
    "",
    "--- --- ---",
    "",
  ].join("\n");
}
