import React, { useCallback, useState } from "react";
import Toast from "../components/Toast";
import styles from "./ToastContext.module.scss";

export type ToasterType = {
  showToast: (text: string) => void;
};

export const ToastContext = React.createContext<ToasterType>({
  showToast: () => {},
});

export function ToastContextProvider(props: React.PropsWithChildren<unknown>) {
  const [toastText, setToastText] = useState<string>("");
  const [shouldShowToast, setShouldShowToast] = useState<boolean>(false);
  const [, setTimeoutHandle] = useState<NodeJS.Timeout | null>(null);
  const showToast = useCallback((text: string) => {
    setToastText(text);
    setShouldShowToast(true);
    setTimeoutHandle(handle => {
      if (handle !== null) {
        window.clearTimeout(handle);
      }
      return setTimeout(() => {
        setShouldShowToast(false);
      }, 2000);
    })
  }, []);
  const toaster = { showToast }

  return (
    <ToastContext.Provider value={toaster}>
      <div className={styles.toastContextRoot}>
        {props.children}
        <Toast text={toastText} show={shouldShowToast} />
      </div>
    </ToastContext.Provider>
  );
}
