import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IProject } from "../lib/types";
import * as firestore from "../lib/firestore";
import { UserContext } from "./UserContext";
import { StatusRecord } from "../lib/status";

export type ProjectWithStatusRecord = {
  project: IProject;
  statusRecord: StatusRecord | undefined;
};
type ProjectsContextType = {
  projects: ProjectWithStatusRecord[] | null;
  createProject: (displayName: string) => Promise<string>;
  deleteProject: (projectId: string) => Promise<void>;
};

export const ProjectsContext = createContext<ProjectsContextType>({
  projects: [],
  createProject: () => Promise.reject("Something wrong in ProjectsContext"),
  deleteProject: () => Promise.reject("Something wrong in ProjectsContext"),
});

export function ProjectsContextProvider(
  props: React.PropsWithChildren<unknown>
) {
  const { user } = useContext(UserContext);
  const [projects, setProjects] = useState<ProjectWithStatusRecord[] | null>(
    null
  );
  const createProject = useCallback(
    (displayName: string) => {
      return new Promise<string>((resolve, reject) => {
        if (user === null) {
          reject();
          return;
        }
        firestore
          .createProject(displayName, user.uid)
          .then((project) => resolve(project.id))
          .catch(reject);
      });
    },
    [user]
  );

  const deleteProject = useCallback(
    (projectId: string) =>
      new Promise<void>((resolve, reject) => {
        if (user === null) {
          reject();
          return;
        }
        firestore
          .deleteProject(projectId, user.uid)
          .then(resolve)
          .catch(reject);
      }),
    [user]
  );

  useEffect(() => {
    if (user === null) return;
    let unsubscribe: () => void = () =>
    console.error(
      "Unsubscriber function was not registered: ProjectsContext"
    );
    firestore.observeProjects(user.uid, (projects) => {
      Promise.all(
        projects.map((p) =>
          firestore
            .getProjectStatus(p.id, user.uid)
            .then((sr) => ({ project: p, statusRecord: sr }))
        )
      ).then(setProjects);
    }).then(u => unsubscribe = u);
    return () => unsubscribe();
  }, [user]);

  return (
    <ProjectsContext.Provider
      value={{ projects, createProject, deleteProject }}
    >
      {props.children}
    </ProjectsContext.Provider>
  );
}
