import panelStyles from "./Panel.module.scss";
import LeftNav from "../components/LeftNav";
import MainNav from "../components/MainNav";
import GeneratedTopics from "./GeneratedTopics";

export default function GeneratedTopicsPanel() {

  return (
    <div className={panelStyles.twoColumnsPanel}>
      <MainNav className={panelStyles.mainNav} />
      <LeftNav className={panelStyles.leftColumn} />
      <GeneratedTopics className={panelStyles.mainColumn} />
    </div>
  );
}
