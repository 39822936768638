import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import firebase from "firebase/app";
import { IUser } from "../lib/types";
import { getOrCreateUser, userDidTouchProject } from "../lib/firestore";
import { signOut } from "../lib/firebaseAuth";

type UserContextType = {
  user: IUser | null;
  uid: string | null;
  logout: () => void;
  touchProject: (projectId: string, uid: string) => Promise<void>;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  uid: null,
  logout: () => {
    throw new Error("UserContext is not set up properly");
  },
  touchProject: () => Promise.reject("Something went wrong in UserContext"),
});

export default function UserContextProvider(
  props: PropsWithChildren<{ user: firebase.User }>
) {
  const [user, _setUser] = useState<IUser | null>(null);
  const [uid, setUid] = useState<string | null>(null);

  const setUser = useCallback((user: IUser | null) => {
    _setUser(user);
    setUid((uid) => (uid === (user?.uid ?? null) ? uid : user?.uid ?? null));
  }, []);

  const logout = useCallback(() => {
    signOut().then((_) => setUser(null));
  }, [setUser]);

  const touchProject = useCallback(
    (projectId: string, uid: string) => {
      return userDidTouchProject(uid, projectId)
        .then(setUser)
        .catch(console.error);
    },
    [setUser]
  );

  useEffect(() => {
    getOrCreateUser(props.user).then(setUser);
  }, [props.user, setUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        uid,
        logout,
        touchProject,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
