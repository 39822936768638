// Typescript definitions for the Status object

import { IProject } from "./types";

export enum Status {
  Error = -1,
  NotStarted = 1,
  InProgress = 2,
  Complete = 3,
  PendingUpdate = 4, // means it has previously completed, but might update soon because of other tasks
}

export interface StatusStatus {
  [key: string]: {
    status: Status;
  };
}
export interface StatusSection {
  status: Status;
  progress?: number;
  files?: {
    [key: string]: {
      status: Status;
      topicModels?: StatusStatus;
    };
  };
  topicModels?: {
    [key: string]: {
      status: Status;
      files?: StatusStatus;
    };
  };
}

export interface StatusRecord {
  mediaUpload: StatusSection;
  mediaTranscription: StatusSection;
  transcriptImport: StatusSection;
  sentimentAnalysis: StatusSection;
  interviewGuide: StatusSection;
  matchGuideToTranscript: StatusSection;
  generateTopicModels: StatusSection;
  reduceTopicModels: StatusSection;
  matchQuoteToTopic: StatusSection;
  sortTopQuotes: StatusSection;
}

export enum ProjectState {
  UNKNOWN,
  ERROR,
  CREATED,
  UPLOADING_MEDIA,
  TRANSCRIBING,
  IMPORTING_TRANSCRIPTION,
  ANALYZING_SENTIMENT,
  PROCESSING_INTERVIEW_GUIDE,
  GENERATING_TOPICS,
  MATCHING_QUOTE_TO_TOPICS,
  SORTING_QUOTES,
  READY,
}

export function projectState(
  statusRecord: StatusRecord | null | undefined
): ProjectState {
  if (statusRecord === undefined) return ProjectState.CREATED;
  if (statusRecord === null) return ProjectState.UNKNOWN;
  if (statusRecord.mediaUpload.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.mediaUpload.status === Status.NotStarted)
    return ProjectState.UPLOADING_MEDIA;
  if (statusRecord.mediaUpload.status !== Status.Complete)
    return ProjectState.UPLOADING_MEDIA;
  if (statusRecord.mediaTranscription.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.mediaTranscription.status === Status.NotStarted)
    return ProjectState.TRANSCRIBING;
  if (statusRecord.mediaTranscription.status !== Status.Complete)
    return ProjectState.TRANSCRIBING;
  if (statusRecord.transcriptImport.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.transcriptImport.status === Status.NotStarted)
    return ProjectState.IMPORTING_TRANSCRIPTION;
  if (statusRecord.transcriptImport.status !== Status.Complete)
    return ProjectState.IMPORTING_TRANSCRIPTION;
  if (statusRecord.interviewGuide.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.interviewGuide.status === Status.NotStarted)
    return ProjectState.PROCESSING_INTERVIEW_GUIDE;
  if (statusRecord.interviewGuide.status !== Status.Complete)
    return ProjectState.PROCESSING_INTERVIEW_GUIDE;
  if (statusRecord.matchGuideToTranscript.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.matchGuideToTranscript.status === Status.NotStarted)
    return ProjectState.PROCESSING_INTERVIEW_GUIDE;
  if (statusRecord.matchGuideToTranscript.status !== Status.Complete)
    return ProjectState.PROCESSING_INTERVIEW_GUIDE;
  if (statusRecord.generateTopicModels.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.generateTopicModels.status !== Status.Complete)
    return ProjectState.GENERATING_TOPICS;
  if (statusRecord.reduceTopicModels.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.reduceTopicModels.status !== Status.Complete)
    return ProjectState.GENERATING_TOPICS;
  if (statusRecord.matchQuoteToTopic.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.matchQuoteToTopic.status !== Status.Complete)
    return ProjectState.MATCHING_QUOTE_TO_TOPICS;
  if (statusRecord.sortTopQuotes.status === Status.Error)
    return ProjectState.ERROR;
  if (statusRecord.sortTopQuotes.status !== Status.Complete)
    return ProjectState.SORTING_QUOTES;

  return ProjectState.READY;
}

export enum SettingsState {
  UNKNOWN,
  NEEDS_FOLDER,
  NEEDS_INTERVIEW_GUIDE,
  NEEDS_FINISH_UP,
  ANALYZING,
  READY,
}

export function settingsState(
  project: IProject | null,
  statusRecord: StatusRecord | null
) {
  if (project === null) return SettingsState.UNKNOWN;

  if (typeof project.boxFolder === "undefined") {
    return SettingsState.NEEDS_FOLDER;
  }
  if ((project.interviewGuide?.length ?? 0) === 0) {
    return SettingsState.NEEDS_INTERVIEW_GUIDE;
  }
  if (!project.doneSetup) {
    return SettingsState.NEEDS_FINISH_UP;
  }

  if (statusRecord == null) return SettingsState.ANALYZING;

  // done with analysis
  if (
    statusRecord.generateTopicModels.status === Status.Complete &&
    // statusRecord.matchGuideToTranscript.status === Status.Complete &&
    statusRecord.matchQuoteToTopic.status === Status.Complete &&
    statusRecord.reduceTopicModels.status === Status.Complete &&
    statusRecord.sortTopQuotes.status === Status.Complete
  ) {
    return SettingsState.READY;
  }

  // ready to re-run analysis
  if (
    statusRecord.generateTopicModels.status === Status.NotStarted &&
    statusRecord.matchQuoteToTopic.status === Status.NotStarted &&
    statusRecord.reduceTopicModels.status === Status.NotStarted &&
    statusRecord.sortTopQuotes.status === Status.NotStarted &&
    statusRecord.matchGuideToTranscript.status === Status.Complete &&
    statusRecord.interviewGuide.status === Status.Complete &&
    statusRecord.mediaTranscription.status === Status.Complete &&
    statusRecord.transcriptImport.status === Status.Complete
  ) {
    return SettingsState.READY;
  }

  return SettingsState.ANALYZING;
}
