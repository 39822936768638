import {
  MouseEvent,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import firebase from "firebase/app";
import InView from "react-intersection-observer";
import { ProjectContext } from "../contexts/ProjectContext";
import {
  getFavorites,
  getSegments2,
  removeTopicFromSegment,
  addTopicToSegment,
} from "../lib/firestore";
import {
  ITopicFilter,
  ISegment,
  ISegmentWithFav,
  IInterview,
  INTERVIEWER,
  InterviewsFilterType,
} from "../lib/types";
import styles from "./Segments.module.scss";
import Segment from "../components/Segment";
import { UserContext } from "../contexts/UserContext";
import { InterviewsContext } from "../contexts/InterviewsContext";
import Mutex from "../lib/Mutex";

// const LIMIT = 21;

type SortOrder = {
  key?: string;
  label: string;
  ascOrDesc: firebase.firestore.OrderByDirection;
};
const SortOrders: SortOrder[] = [
  { key: "score", label: "Relevancy", ascOrDesc: "desc" },
  { key: "updatedAt", label: "Timestamp", ascOrDesc: "asc" },
];

interface ISegmentsCursor {
  lastDocument?: firebase.firestore.DocumentSnapshot;
  interviewerFilter?: boolean;
  topicFilter?: ITopicFilter;
  interviewsFilter?: InterviewsFilterType;
  sortOrder?: SortOrder;
}

function didTopicFilterChange(a?: ITopicFilter, b?: ITopicFilter) {
  return a?.topicId !== b?.topicId || a?.score !== b?.score;
}

function didInterviewsFilterChange(
  a?: InterviewsFilterType,
  b?: InterviewsFilterType
) {
  if (a?.op == null && b?.op == null) return false;
  if (a?.op !== b?.op) return true;
  if (a?.interviews == null && b?.interviews != null) return true;
  if (a?.interviews != null && b?.interviews == null) return true;
  return (
    (a?.interviews?.map((p) => p.id) ?? []).sort().join(";;") !==
    (b?.interviews?.map((p) => p.id) ?? []).sort().join(";;")
  );
}

const mutex = new Mutex();

function usePaginatedSegments(topicFilter?: ITopicFilter) {
  const { project } = useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const { excludeInterviews, includeInterviewer, interviews } =
    useContext(InterviewsContext);

  const [segments, setSegments] = useState<ISegmentWithFav[] | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const segmentDisplayName = useCallback(
    (segment: ISegment) => {
      const interview = interviews.find((iv) => iv.id === segment.interview);
      return segment.interviewer ?? false
        ? INTERVIEWER.displayName
        : interview === undefined
        ? "(missing interview?)"
        : interview.displayName;
    },
    [interviews]
  );

  const DefaultInterviewsFilter: InterviewsFilterType = useMemo(
    () => ({
      op: "exclude",
      interviews: excludeInterviews,
    }),
    [excludeInterviews]
  );

  const [interviewsFilter, setInterviewsFilter] =
    useState<InterviewsFilterType>(DefaultInterviewsFilter);
  const [interviewerFilter, setInterviewerFilter] =
    useState<boolean>(includeInterviewer);

  const [cursor, setCursor] = useState<ISegmentsCursor>({
    interviewerFilter,
    interviewsFilter,
    topicFilter,
    sortOrder: {
      label: "Def",
      key: "updatedAt",
      ascOrDesc: "asc",
    },
  });
  const [nextCursor, setNextCursor] = useState<ISegmentsCursor | null>(null);
  const [loadMoreRequested, setLoadMoreRequested] = useState<boolean>(false);

  /**
   * This is the main effect of the hook that eventually collects segments to render.
   * This block executes when the `cursor` state is updated.
   *
   * Note the use of `mutex`. This effect could be called in a rapid succession, in which case
   * the cursor may not be updated yet from the previous call that results in multiple calls to
   * Firestore for the same result set. Mutex locks out subsequent calls until it's released,
   * so the following call would use the new cursor to fetch results.
   */
  useEffect(() => {
    mutex.acquire().then((release) => {
      if (project?.id == null || user == null) {
        release();
        return;
      }
      const topicId = cursor.topicFilter?.topicId
      if (topicId == null) {
        release();
        return;
      }
      getSegments2(
        project.id,
        user.uid,
        // LIMIT,
        cursor.interviewerFilter,
        topicId,
        cursor.interviewsFilter,
        cursor.topicFilter?.score,
        cursor.sortOrder?.key,
        cursor.sortOrder?.ascOrDesc,
        cursor.lastDocument
      )
        .then((data) => {
          if (data.segments.length > 0) {
            const newCursor = { ...cursor };
            newCursor.lastDocument = data.cursor?.lastDocument;
            setNextCursor(newCursor);
          } else {
            setNextCursor(null);
          }

          return data.segments;
        })
        .then((segments) => {
          getFavorites(project.id, user.uid).then((favs) => {
            const favedSegments = segments.map((s) => {
              if (favs.findIndex((f) => f.id === s.id) < 0) {
                return { ...s, fav: false, displayName: segmentDisplayName(s) };
              } else {
                return { ...s, fav: true, displayName: segmentDisplayName(s) };
              }
            });
            setSegments((t) => [...(t ?? []), ...favedSegments]);
          });
        })
        .finally(release);
    });
  }, [project, user, cursor, segmentDisplayName]);

  /**
   * This is the secondary effect of the hook that does "load more" ability.
   * This block executes when `loadMore` function is called outside of the hook.
   * It loads the next page of the segments by setting the `nextCursor` to the `cursor`,
   * that in turn executes the useEffect block above.
   *
   * Note the use of the mutex. This mutex enables mutually exclusive access to `nextCursor` state
   * between this block and the block above that updates the `nextCursor` state, so it prevents
   * multiple calls of the same query from reaching Firestore.
   */
  useEffect(() => {
    if (loadMoreRequested) {
      mutex.acquire().then((release) => {
        if (nextCursor !== null) {
          setCursor(nextCursor);
        }
        release();
        setLoadMoreRequested(false);
      });
    }
  }, [nextCursor, loadMoreRequested]);

  /**
   * This is the ternary effect of the hook that does filtering ability.
   * This block executes when a topic change was propagated from the parent via a prop,
   * or a interviews changes either in the InterviewsContext or in the filter dropdown.
   *
   * Note the use of the mutex. This mutex enables mutually exclusive access to `cursor` state
   * between this block and the block above that updates the `cursor` state, so it prevents
   * multiple calls of the same query from reaching Firestore.
   */
  useEffect(() => {
    mutex.acquire().then((release) => {
      setCursor((c) => {
        const topicFilterChanged = didTopicFilterChange(
          topicFilter,
          c.topicFilter
        );
        const interviewsFilterChanged = didInterviewsFilterChange(
          interviewsFilter,
          c.interviewsFilter
        );

        if (
          !topicFilterChanged &&
          !interviewsFilterChanged &&
          interviewerFilter === c.interviewerFilter
        ) {
          return c;
        }

        setSegments(null);
        setSelectedIndex(-1);
        setNextCursor(null);

        return {
          sortOrder: c.sortOrder,
          topicFilter: topicFilterChanged ? topicFilter : c.topicFilter,
          interviewsFilter: interviewsFilterChanged
            ? interviewsFilter
            : c.interviewsFilter,
          interviewerFilter:
            interviewerFilter !== c.interviewerFilter
              ? interviewerFilter
              : c.interviewerFilter,
        };
      });
      release();
    });
  }, [interviewerFilter, interviewsFilter, topicFilter]);

  /**
   * A call to loadMore merely requests to load more when it's ready.
   * The two useEffect blocks above uses `cursor` state to determine when it's ready
   * and they use a mutex to prevent race conditions from happening.
   */
  const loadMore = useCallback(() => setLoadMoreRequested(true), []);

  const addInterviewToFilter = useCallback((interview: IInterview) => {
    if (interview.id === INTERVIEWER.id) {
      setInterviewerFilter(true);
    } else {
      setInterviewsFilter((f) => {
        if (f.op === "include") {
          return { op: "include", interviews: [...f.interviews, interview] };
        } else {
          return { op: "include", interviews: [interview] };
        }
      });
    }
  }, []);

  const removeInterviewFromFilter = useCallback(
    (interview: IInterview) => {
      if (interview.id === INTERVIEWER.id) {
        setInterviewerFilter(false);
      } else {
        setInterviewsFilter((f) => {
          if (f.op === "include") {
            const p = f.interviews.filter((p) => p.id !== interview.id);
            if (p.length === 0) {
              return DefaultInterviewsFilter;
            } else {
              return { op: "include", interviews: p };
            }
          } else {
            return DefaultInterviewsFilter;
          }
        });
      }
    },
    [DefaultInterviewsFilter]
  );

  const resetInterviewsFilter = useCallback(() => {
    setInterviewsFilter(DefaultInterviewsFilter);
  }, [DefaultInterviewsFilter]);

  const updateSortOrder = (sortOrder: SortOrder) => {
    setSegments(null);
    setSelectedIndex(-1);
    setNextCursor(null);
    setCursor((c) => ({ ...c, sortOrder, lastDocument: undefined }));
  };

  const removeTopic = useCallback(
    async (segment: ISegmentWithFav, topicId: string) => {
      if (project === null || user === null) return;
      const updatedSegment = await removeTopicFromSegment(
        project.id,
        user.uid,
        segment.id,
        topicId
      );
      const newSegment = {
        ...updatedSegment,
        fav: segment.fav,
        displayName: segment.displayName,
      };
      setSegments((segments) => {
        const newSegments = [...(segments ?? [])];
        const index = newSegments.findIndex((s) => s.id === updatedSegment.id);
        if (index < 0) return segments;
        newSegments.splice(index, 1, newSegment);
        return newSegments;
      });
      // TODO call setNextCursor to update the list? the segment could be instantly removed
      // from the view if TopicFilter was there if we do that.
    },
    [project, user]
  );

  const addTopic = useCallback(
    async (segment: ISegmentWithFav, topicId: string) => {
      if (project === null || user === null) return;
      const updatedSegment = await addTopicToSegment(
        project.id,
        user.uid,
        segment.id,
        topicId
      );
      const newSegment = {
        ...updatedSegment,
        fav: segment.fav,
        displayName: segment.displayName,
      };
      setSegments((segments) => {
        const newSegments = [...(segments ?? [])];
        const index = newSegments.findIndex((s) => s.id === updatedSegment.id);
        if (index < 0) return segments;
        newSegments.splice(index, 1, newSegment);
        return newSegments;
      });
    },
    [project, user]
  );

  const sortOrderIndex = SortOrders.findIndex(
    (s) => s.key === cursor.sortOrder?.key
  );

  return [
    segments,
    loadMore,
    interviewerFilter,
    interviewsFilter,
    addInterviewToFilter,
    removeInterviewFromFilter,
    resetInterviewsFilter,
    sortOrderIndex < 0 ? 0 : sortOrderIndex,
    updateSortOrder,
    selectedIndex,
    setSelectedIndex,
    removeTopic,
    addTopic,
  ] as const;
}

export default function Segments(props: {
  className?: string;
  topicFilter?: ITopicFilter;
  onSelectSegment: (segment?: ISegment) => void;
}) {
  const [
    segments,
    loadMore,
    interviewerFilter,
    interviewsFilter,
    addInterviewToFilter,
    removeInterviewFromFilter,
    resetInterviewsFilter,
    sortOrderIndex,
    updateSortOrder,
    selectedIndex,
    setSelectedIndex,
    removeTopic,
    addTopic,
  ] = usePaginatedSegments(props.topicFilter);

  const onSelectSegment = useCallback(
    (index: number, segment: ISegment) => {
      if (selectedIndex === index) {
        props.onSelectSegment(undefined);
        setSelectedIndex(-1);
      } else {
        props.onSelectSegment(segment);
        setSelectedIndex(index);
      }
    },
    [selectedIndex, setSelectedIndex, props]
  );

  const [isScrollFrozen, setScrollFrozen] = useState<boolean>(false);

  return (
    <div
      className={`${styles.segmentsContainer} ${props.className}${
        isScrollFrozen ? ` ${styles.frozen}` : ""
      }`}
    >
      <Subnav
        topic={props.topicFilter}
        numSegments={props.topicFilter?.segment_count ?? 0}
        interviwerFilter={interviewerFilter}
        selectedInterviewsFilter={
          interviewsFilter.op === "include" ? interviewsFilter.interviews : []
        }
        onAddInterviewToFilter={addInterviewToFilter}
        onRemoveInterviewFromFilter={removeInterviewFromFilter}
        onResetInterviewsFilter={resetInterviewsFilter}
        selectedSortOrder={sortOrderIndex}
        onUpdateSortOrder={updateSortOrder}
        onToggleDropdown={setScrollFrozen}
      />

      <div className={styles.scrollingContainer}>
        <div className={styles.segments}>
          {segments === null ? (
            <Loading />
          ) : segments.length === 0 ? (
            <Empty topic={props.topicFilter?.name ?? "(no topic)"} />
          ) : (
            segments.map((segment, i) => (
              <Segment
                key={i}
                segment={segment}
                isSelected={i === selectedIndex}
                onSelectSegment={() => onSelectSegment(i, segment)}
                onRemoveTopic={(topicId) => removeTopic(segment, topicId)}
                onAddTopic={(topicId) => addTopic(segment, topicId)}
              />
            ))
          )}
        </div>
        <InView
          as="div"
          onChange={(isInView) => {
            if (!isInView) return;
            loadMore();
          }}
        >
          &nbsp;
        </InView>
      </div>
    </div>
  );
}

function Subnav(props: {
  topic?: ITopicFilter;
  numSegments: number;
  interviwerFilter: boolean;
  selectedInterviewsFilter: IInterview[];
  onAddInterviewToFilter: (interview: IInterview) => void;
  onRemoveInterviewFromFilter: (interview: IInterview) => void;
  onResetInterviewsFilter: () => void;
  selectedSortOrder: number;
  onUpdateSortOrder: (sortOrder: SortOrder) => void;
  onToggleDropdown: (isOpen: boolean) => void;
}) {
  const [isFilterOpen, isSorterOpen, toggleFilter, toggleSorter] =
    useMutuallyExclusiveDropdowns(props.onToggleDropdown);

  return (
    <div className={styles.subnav}>
      <SubnavTopic topic={props.topic} numSegments={props.numSegments} />
      <hr className={styles.separator} />
      <div className={styles.controls}>
        <SubnavFilter
          interviewerFilter={props.interviwerFilter}
          selectedInterviewsFilter={props.selectedInterviewsFilter}
          onAddInterviewToFilter={props.onAddInterviewToFilter}
          onRemoveInterviewFromFilter={props.onRemoveInterviewFromFilter}
          onResetInterviewsFilter={props.onResetInterviewsFilter}
          onToggleDropdown={toggleFilter}
          isDropdownActive={isFilterOpen}
        />
        <SubnavSorter
          selectedSortOrder={props.selectedSortOrder}
          onUpdateSortOrder={props.onUpdateSortOrder}
          onToggleDropdown={toggleSorter}
          isDropdownActive={isSorterOpen}
        />
      </div>
    </div>
  );
}

function SubnavTopic(props: { topic?: ITopicFilter; numSegments: number }) {
  if (props.topic == null) return null;
  return (
    <div className={styles.subnavTopic}>
      <h1>
        <span className={styles.subnavTopicName}>{props.topic.name}</span>
        <span className={`numberBadge dark ${styles.subnavTopicNumSegments}`}>
          {props.numSegments}
        </span>
      </h1>
      <div className={styles.topicTags}>
        {props.topic.keywords.map((keyword) => (
          <span key={keyword} className={`keyword ${styles.topicTag}`}>
            {keyword}
          </span>
        ))}
      </div>
    </div>
  );
}

function SubnavFilter(props: {
  interviewerFilter: boolean;
  selectedInterviewsFilter: IInterview[];
  onAddInterviewToFilter: (interview: IInterview) => void;
  onRemoveInterviewFromFilter: (interview: IInterview) => void;
  onResetInterviewsFilter: () => void;
  onToggleDropdown: () => void;
  isDropdownActive: boolean;
}) {
  const { interviews, excludeInterviews, includeInterviewer } =
    useContext(InterviewsContext);
  const filters = Array.from(interviews).filter(
    (p) => excludeInterviews.findIndex((interview) => interview.id === p.id) < 0
  );
  if (includeInterviewer) {
    filters.unshift(INTERVIEWER);
  }

  const onToggleDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    props.onToggleDropdown();
  };

  const selectedInterviewsIds = props.selectedInterviewsFilter.reduce<{
    [key: string]: number;
  }>((c, v) => {
    c[v.id] = 1;
    return c;
  }, {});

  const isInterviewSelected = useCallback(
    (interview: IInterview) => {
      if (interview.id === INTERVIEWER.id) return props.interviewerFilter;
      return typeof selectedInterviewsIds[interview.id] !== "undefined";
    },
    [props.interviewerFilter, selectedInterviewsIds]
  );

  return (
    <button
      className={`${styles.control} ${styles.filter}`}
      onClick={onToggleDropdown}
    >
      <div>
        <span className={`text ${styles.controlCaption}`}>Filter By:</span>
        <span className={`subhead ${styles.controlValue}`}>
          {props.selectedInterviewsFilter.length === 0
            ? "All"
            : props.selectedInterviewsFilter
                .map((p) => p.displayName)
                ?.join(", ")}
        </span>
      </div>
      <div
        className={`${styles.dropdownListContainer}${
          props.isDropdownActive ? ` ${styles.active}` : ""
        }`}
      >
        <ul className={styles.dropdownList}>
          {filters.map((interview) => (
            <li
              key={interview.id}
              className={`${styles.dropdownListItem}${
                isInterviewSelected(interview) ? ` ${styles.selected}` : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onToggleDropdown();
                if (isInterviewSelected(interview)) {
                  props.onRemoveInterviewFromFilter(interview);
                } else {
                  props.onAddInterviewToFilter(interview);
                }
              }}
            >
              <div className={`text`}>{interview.displayName}</div>
            </li>
          ))}
          <li
            key={-1}
            className={`${styles.dropdownListItem} ${styles.sticky}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.onToggleDropdown();
              props.onResetInterviewsFilter();
            }}
          >
            <div className={`subhead`}>Reset All</div>
          </li>
        </ul>
      </div>
    </button>
  );
}

function SubnavSorter(props: {
  selectedSortOrder: number;
  onUpdateSortOrder: (sortOrder: SortOrder) => void;
  onToggleDropdown: () => void;
  isDropdownActive: boolean;
}) {
  const selected = SortOrders[props.selectedSortOrder];

  const onToggleDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    props.onToggleDropdown();
  };

  return (
    <button
      className={`${styles.sort} ${styles.control}`}
      onClick={onToggleDropdown}
    >
      <div>
        <span className={`text ${styles.controlCaption}`}>Sort By:</span>
        <span className={`subhead ${styles.controlValue}`}>
          {selected.label}
        </span>
      </div>
      <div
        className={`${styles.dropdownListContainer}${
          props.isDropdownActive ? ` ${styles.active}` : ""
        }`}
      >
        <ul className={styles.dropdownList}>
          {SortOrders.map((so, i) => (
            <li
              key={i}
              className={`${styles.dropdownListItem}${
                so.key === selected.key ? ` ${styles.selected}` : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onToggleDropdown();
                props.onUpdateSortOrder(so);
              }}
            >
              <div className={`text`}>{so.label}</div>
            </li>
          ))}
        </ul>
      </div>
    </button>
  );
}

function useMutuallyExclusiveDropdowns(
  onToggleDropdown: (isOpen: boolean) => void
) {
  const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
  const [isSorterOpen, setSorterOpen] = useState<boolean>(false);

  const toggleFilter = useCallback(() => {
    setFilterOpen((wasOpen) => {
      if (!wasOpen) {
        setSorterOpen(false);
      }
      return !wasOpen;
    });
  }, []);

  const toggleSorter = useCallback(() => {
    setSorterOpen((wasOpen) => {
      if (!wasOpen) {
        setFilterOpen(false);
      }
      return !wasOpen;
    });
  }, []);

  useEffect(() => {
    onToggleDropdown(isFilterOpen || isSorterOpen);
  }, [onToggleDropdown, isFilterOpen, isSorterOpen]);

  return [isFilterOpen, isSorterOpen, toggleFilter, toggleSorter] as const;
}

function Loading() {
  return (
    <div className="loading-layer active">
      <div className="loading-indicator" />
    </div>
  );
}

function Empty(props: { topic: string }) {
  return <div>No quotes found for "{props.topic}".</div>;
}
