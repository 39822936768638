import { useCallback, useContext, useEffect, useState } from "react";
import { TopicsContext } from "../contexts/TopicsContext";
import { IProject } from "../lib/types";
import { observeNumFavorites } from "../lib/firestore";
import { ProjectContext } from "../contexts/ProjectContext";
import styles from "./LeftNav.module.scss";
import { UserContext } from "../contexts/UserContext";
import { Link, useLocation } from "react-router-dom";
import { ProjectStatusContext } from "../contexts/ProjectStatusContext";
import { Status } from "../lib/status";

function useNumFavorites() {
  const { project } = useContext(ProjectContext);
  const { user } = useContext(UserContext);
  const [numFavorites, setNumFavorites] = useState<number>(0);
  useEffect(() => {
    if (user === null) return () => {};
    if (project?.id == null) return () => {};
    let unsubscribe: () => void = () =>
      console.error("Unsubscriber function was not registered: LeftNav");
    observeNumFavorites(project.id, user.uid, setNumFavorites).then(
      (u) => (unsubscribe = u)
    );
    return () => unsubscribe();
  }, [project, user, setNumFavorites]);
  return numFavorites;
}

export function useQueryParams() {
  const location = useLocation();
  const queries = location.search.split(/[?&]/);
  const args: { [key: string]: string } = {};
  let score = 0.0;
  if (queries.length > 1) {
    for (let i = 1; i < queries.length; i++) {
      const q = queries[i].split("=");
      args[q[0]] = q[1];
    }
    score = parseFloat(args.score);
    if (isNaN(score) || score > 1.0 || score < 0.0) score = 0.0;
  }
  return { score } as const;
}

function useIsSelectedFunctions(project: IProject | null) {
  const location = useLocation();
  const isPathSelected = useCallback(
    (path: string) => {
      if (project == null) return false;
      const paths = location.pathname.split("/");
      return (
        paths.join("") ===
        `/projects/${project?.id}/${path}`.split("/").join("")
      );
    },
    [location, project]
  );

  return isPathSelected;
}

export default function LeftNav(props: { className?: string }) {
  const { project } = useContext(ProjectContext);
  const { topics } = useContext(TopicsContext);
  const statusRecord = useContext(ProjectStatusContext);
  const numFavorites = useNumFavorites();
  const { score } = useQueryParams();
  const isPathSelected = useIsSelectedFunctions(project);

  return (
    <div className={`${styles.leftNav} ${props.className}`}>
      <h3
        className={`${styles.topicsHeading}${
          isPathSelected("topics") ? ` ${styles.selected}` : ""
        }`}
      >
        <Link to={`/projects/${project?.id}/topics?score=${score.toFixed(1)}`}>
          <span className={styles.heading}>Topics</span>
        </Link>
      </h3>
      <ul className={styles.topics}>
        {((topics.length === 0 && statusRecord?.matchQuoteToTopic?.status) ??
          Status.NotStarted) === Status.Complete ? (
          <li key="review-topic" className={styles.reviewTopicsButton}>
            <Link
              className={`button secondary`}
              to={`/projects/${project?.id}/topics/review?score=${score.toFixed(
                1
              )}`}
            >
              Review Topics
            </Link>
          </li>
        ) : null}
        {topics.map((topic) => (
          <li key={topic.topicId}>
            <Link
              to={`/projects/${project?.id}/topics/${
                topic.topicId
              }?score=${score.toFixed(1)}`}
              className={`${styles.topicLink}${
                isPathSelected(`topics/${topic.topicId}`) ? ` ${styles.selected}` : ""
              }`}
            >
              <h4>{topic.name}</h4>
            </Link>
          </li>
        ))}
      </ul>
      <h3
        className={`${styles.interviewsHeading}${
          isPathSelected("interviews") ? ` ${styles.selected}` : ""
        }`}
      >
        <Link
          className={styles.heading}
          to={`/projects/${project?.id}/interviews?score=${score.toFixed(1)}`}
        >
          Interviews
        </Link>
      </h3>
      <h3
        className={`${styles.questionsHeading}${
          isPathSelected("questions") ? ` ${styles.selected}` : ""
        }`}
      >
        <Link
          className={styles.heading}
          to={`/projects/${project?.id}/questions?score=${score.toFixed(1)}`}
        >
          Questions
        </Link>
      </h3>
      <h3
        className={`${styles.favoritesHeading}${
          isPathSelected("favorites") ? ` ${styles.selected}` : ""
        }`}
      >
        <Link
          to={`/projects/${project?.id}/favorites?score=${score.toFixed(1)}`}
          className={styles.favoritesLink}
        >
          <span className={styles.heading}>Favorites</span>
          <span className={`numberBadge ${styles.numFavorites}`}>
            {numFavorites}
          </span>
        </Link>
      </h3>
    </div>
  );
}
