import firebase from "firebase/app";
import "firebase/firestore";
import { StatusRecord } from "./status";
import {
  IInterview,
  IInterviewDetail,
  IInterviewView,
  IProject,
  IProjectWithRequiredFields,
  ISegment,
  ITopic,
  IUser,
  InterviewsFilterType,
  IUserTopic,
  IGeneratedTopic,
  IQuestion,
} from "./types";
import { unshiftUnique } from "./util";

const LIMIT = 20;

export async function createUser(user: firebase.User): Promise<IUser> {
  const db = firebase.firestore();
  return db
    .collection("users")
    .doc(user.uid)
    .set({
      displayName: user.displayName,
      email: user.email,
      uid: user.uid,
      admin: false,
    })
    .then(() => db.doc(`users/${user.uid}`).get())
    .then((dss) => ({ ...dss.data() } as IUser));
}

export async function getOrCreateUser(user: firebase.User): Promise<IUser> {
  const db = firebase.firestore();
  return db
    .doc(`users/${user.uid}`)
    .get()
    .then((dss) => {
      if (dss.exists) {
        return { ...dss.data() } as IUser;
      } else {
        return createUser(user);
      }
    });
}

export async function userDidTouchProject(
  uid: string,
  projectId: string
): Promise<IUser> {
  const db = firebase.firestore();
  return db
    .doc(`users/${uid}`)
    .get()
    .then((dss) => {
      if (dss.exists) {
        const projects: string[] = dss.get("projectsTouched") ?? [];
        const updated = unshiftUnique(projects, projectId);
        return dss.ref
          .update({
            projectsTouched: updated,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => dss.ref.get())
          .then((d) => ({ ...d.data() } as IUser));
      } else {
        throw new Error(`User not found ${uid}`);
      }
    });
}

async function _getAuthorizedProjects(uid: string) {
  const db = firebase.firestore();
  const user = await db
    .doc(`users/${uid}`)
    .get()
    .then((dss) => ({ ...dss.data() } as IUser));
  const coll = db.collection("projects");
  if (user.admin) {
    return coll;
  } else {
    return coll.where("authorizedUsers", "array-contains", uid);
  }
}

async function _getAuthorizedProject(projectId: string, uid: string) {
  const coll = await _getAuthorizedProjects(uid);
  const q = coll.where(
    firebase.firestore.FieldPath.documentId(),
    "==",
    projectId
  );
  return q.get().then((qss) => {
    if (qss.size > 0) {
      return qss.docs[0].ref;
    } else {
      console.trace();
      throw new Error("User is not authorized to access the project");
    }
  });
}

export async function observeProjects(
  uid: string,
  listener: (projects: IProject[]) => void
) {
  return _getAuthorizedProjects(uid).then((query) => {
    return query.onSnapshot((qss) => {
      const projects = qss.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as IProject)
      );
      Promise.all(
        projects.map((p) =>
          query.firestore
            .doc(`projects/${p.id}/topics/${p.id}`)
            .get()
            .then((dss) => {
              p.topics_count = Object.keys(dss.get("topics") ?? {}).length;
              return p;
            })
        )
      ).then(listener);
    });
  });
}

export async function createProject(
  displayName: string,
  uid: string
): Promise<IProject> {
  const db = firebase.firestore();
  const projectToCreate: IProjectWithRequiredFields = {
    displayName,
    doneSetup: false,
    modelGenerationCode: 0,
    modelGenerationMessage: "",
    neverDelete: false,
    authorizedUsers: [uid],
    // @ts-ignore
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    // @ts-ignore
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  return db
    .collection("projects")
    .add(projectToCreate)
    .then(async (doc) => {
      // Create views collection and empty documents
      const views = doc.collection("views");
      await views.doc("favorites").set({ viewType: "favorites" });
      await views.doc("interviews").set({ viewType: "interviews" });
      await views.doc("topics").set({ viewType: "topics" });
      return doc.get();
    })
    .then((dss) => ({ ...dss.data(), id: dss.id } as IProject));
}

export async function updateProject(
  project: Partial<IProject>,
  uid: string
): Promise<IProject> {
  const data: Partial<IProject> = { ...project };
  const projectId = data.id;
  if (projectId === undefined) {
    throw new Error("Project ID cannot be undefined");
  }
  delete data.id;
  return _getAuthorizedProject(projectId, uid).then((doc) =>
    doc
      .update(data)
      .then((_) => doc)
      .then((_) => doc.get())
      .then((dss) => ({ ...dss.data(), id: dss.id } as IProject))
  );
}

export async function deleteProject(projectId: string, uid: string) {
  const doc = await _getAuthorizedProject(projectId, uid);
  const views = await doc.collection("views").get();
  if (!views.empty) {
    views.docs.forEach((doc) => doc.ref.delete());
  }
  const questions = await doc.collection("questions").get();
  if (!questions.empty) {
    questions.docs.forEach((doc) => doc.ref.delete());
  }
  return doc.delete();
}

export function observeProject(
  projectId: string,
  uid: string,
  listener: (project: IProject | null) => void
) {
  return _getAuthorizedProject(projectId, uid).then((doc) =>
    doc.onSnapshot((dss) => {
      if (!dss.exists) {
        listener(null);
      } else {
        const project = { ...dss.data(), id: dss.id } as IProject;
        listener(project);
      }
    })
  );
}

export async function getTopics(
  projectId: string,
  uid: string
): Promise<ITopic[]> {
  const doc = await _getAuthorizedProject(projectId, uid);
  return doc
    .collection("views")
    .doc("topics")
    .get()
    .then((doc) => {
      const topics = doc.get("topics");
      if (topics == null) return [];
      return topics.map(
        (topic: any, i: number) => ({ ...topic, index: i } as ITopic)
      );
    });
}

export async function observeGeneratedTopics(
  projectId: string,
  uid: string,
  listener: (topics: IGeneratedTopic[]) => void
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("topics")
    .doc(projectId)
    .onSnapshot((dss) => {
      const topics = dss.get("topics");
      if (topics == null) return;
      const res = Object.keys(topics).reduce<IGeneratedTopic[]>(
        (current, topicId) => {
          if (topics[topicId].keywords != null) {
            current.push({
              keywords: [],
              ...topics[topicId],
              topicId,
            } as IGeneratedTopic);
          }
          return current;
        },
        []
      );
      listener(res);
    });
}

export async function observeUserTopics(
  projectId: string,
  uid: string,
  listener: (topics: ITopic[]) => void
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("views")
    .doc("topics")
    .onSnapshot((dss) => {
      const topics = dss.get("topics");
      if (topics == null) return;
      const res = Object.keys(topics).map(
        (topicId) => ({ ...topics[topicId], topicId } as IUserTopic)
      );
      Promise.all(
        res.map((utopic) =>
          getGeneratedTopic(projectId, uid, utopic.topicId).then(
            (gtopic) => ({ ...utopic, ...gtopic } as ITopic)
          )
        )
      ).then(listener);
    });
}

export async function getGeneratedTopic(
  projectId: string,
  uid: string,
  topicId: string
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("topics")
    .doc(projectId)
    .get()
    .then(
      (dss) => dss.get("topics") as { [id: string]: Partial<IGeneratedTopic> }
    )
    .then((gts) => (gts == null ? null : { keywords: [], ...gts[topicId] }));
}

export async function getUserTopic(
  projectId: string,
  uid: string,
  topicId: string
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("views")
    .doc("topics")
    .get()
    .then((doc) =>
      doc.get(topicId) == null ? null : (doc.get(topicId) as IUserTopic)
    );
}

export async function saveUserTopics(
  projectId: string,
  uid: string,
  topics: IGeneratedTopic[]
) {
  const project = await _getAuthorizedProject(projectId, uid);
  const topicsDoc = project.collection("views").doc("topics");
  const topicsMap = topics.reduce<{ [key: string]: IUserTopic }>((c, v) => {
    c[v.topicId] = {
      topicId: v.topicId,
      name: v.keywords[0] ?? "(no keywords)",
    };
    return c;
  }, {});
  return topicsDoc.update({ topics: topicsMap });
}

export async function updateTopic(
  projectId: string,
  uid: string,
  topic: IUserTopic,
  name: string
) {
  const project = await _getAuthorizedProject(projectId, uid);
  const topicsDoc = project.collection("views").doc("topics");
  const userTopic: IUserTopic = await topicsDoc
    .get()
    .then((d) => d.get(topic.topicId));
  const newTopic = userTopic == null ? { name } : { ...userTopic, name };
  const newData: { [key: string]: unknown } = {};
  newData[topic.topicId] = newTopic;
  return topicsDoc.update(newData);
}

export async function getUserInterviews(
  projectId: string,
  uid: string,
  interviewId: string
): Promise<IInterviewDetail | null> {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("views")
    .doc("interviews")
    .get()
    .then((doc) =>
      doc.get(interviewId) == null
        ? null
        : (doc.get(interviewId) as IInterviewDetail)
    );
}

export async function updateInterviewDisplayName(
  projectId: string,
  uid: string,
  interviewId: string,
  name: string
): Promise<void> {
  const project = await _getAuthorizedProject(projectId, uid);
  const interviewsDoc = project.collection("views").doc("interviews");
  const userInterview: IInterviewView = await interviewsDoc
    .get()
    .then((d) => d.get(interviewId));
  const newIv =
    userInterview == null
      ? { displayName: name }
      : { ...userInterview, displayName: name };
  const newData: { [key: string]: unknown } = {};
  newData[interviewId] = newIv;
  return interviewsDoc.update(newData);
}

async function modifyTagOfInterview(
  projectId: string,
  uid: string,
  interviewId: string,
  tag: string,
  value: boolean
) {
  const project = await _getAuthorizedProject(projectId, uid);
  const interviewsDoc = project.collection("views").doc("interviews");
  const userInterview: IInterviewView = await interviewsDoc
    .get()
    .then((d) => d.get(interviewId));
  const newTags: { [tag: string]: boolean } =
    userInterview == null ? {} : { ...userInterview.tags };
  newTags[tag] = value;
  const newIv =
    userInterview == null
      ? { tags: newTags }
      : { ...userInterview, tags: newTags };
  const newData: { [key: string]: unknown } = {};
  newData[interviewId] = newIv;
  return interviewsDoc.update(newData);
}

export async function addTagToInterview(
  projectId: string,
  uid: string,
  interviewId: string,
  tag: string
): Promise<void> {
  return modifyTagOfInterview(projectId, uid, interviewId, tag, true);
}

export async function removeTagFromInterview(
  projectId: string,
  uid: string,
  interviewId: string,
  tag: string
): Promise<void> {
  return modifyTagOfInterview(projectId, uid, interviewId, tag, false);
}

function makeInterview(id: string, obj?: object): IInterview {
  const r = { ...obj } as IInterview;
  r.ivid = r.id;
  r.id = id;
  return r;
}

export async function observeInterviews(
  projectId: string,
  uid: string,
  listener: (interviews: IInterviewDetail[]) => void
) {
  const project = await _getAuthorizedProject(projectId, uid);
  const unsubInterviews = project.collection("interviews").onSnapshot((qss) => {
    const interviews = qss.docs.map((doc) => makeInterview(doc.id, doc.data()));
    Promise.all(
      interviews.map((iv) =>
        getUserInterviews(projectId, uid, iv.id).then(
          (ivv) =>
            ({
              ...iv,
              ...(ivv === null ? { tags: {} } : ivv),
            } as IInterviewDetail)
        )
      )
    ).then((res) => listener(res));
  });
  const unsubViews = project
    .collection("views")
    .doc("interviews")
    .onSnapshot((dss) => {
      project
        .collection("interviews")
        .get()
        .then((qss) =>
          qss.docs.map((doc) => {
            const iv = makeInterview(doc.id, doc.data());
            const ivv = dss.get(iv.id);
            return {
              ...iv,
              ...(ivv === null ? { tags: {} } : ivv),
            } as IInterviewDetail;
          })
        )
        .then(listener);
    });
  return () => {
    unsubInterviews();
    unsubViews();
  };
}

export async function getInterview(
  projectId: string,
  uid: string,
  interviewId: string
): Promise<IInterview> {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("interviews")
    .doc(interviewId)
    .get()
    .then((doc) => makeInterview(doc.id, doc.data()));
}

export async function getFavorites(
  projectId: string,
  uid: string
): Promise<ISegment[]> {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection(`views/favorites/${uid}`)
    .get()
    .then((qss) =>
      Promise.all(
        qss.docs.map((doc) =>
          doc
            .get("segmentRef")
            .get()
            .then(
              (doc: firebase.firestore.DocumentSnapshot) =>
                ({
                  ...doc.data(),
                  id: doc.id,
                } as ISegment)
            )
        )
      )
    );
}

export async function addFavorite(
  projectId: string,
  uid: string,
  segment: ISegment
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection(`views/favorites/${uid}`)
    .doc(segment.id)
    .set({
      segmentRef: project.collection("segments").doc(segment.id),
    });
}

export async function removeFavorite(
  projectId: string,
  uid: string,
  segment: ISegment
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project.collection(`views/favorites/${uid}`).doc(segment.id).delete();
}

export async function observeNumFavorites(
  projectId: string,
  uid: string,
  callback: (numFavorites: number) => void
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project.collection(`views/favorites/${uid}`).onSnapshot((qss) => {
    callback(qss.size);
  });
}

export async function getSegments2(
  projectId: string,
  uid: string,
  // limit: number = LIMIT,
  includeInterviewer: boolean = false,
  topicId: string,
  interviewsFilter?: InterviewsFilterType,
  topicScoreThreshold?: number,
  orderBy: string = "updatedAt",
  ascOrDesc: firebase.firestore.OrderByDirection = "asc",
  lastDocument?: firebase.firestore.DocumentSnapshot
): Promise<{
  segments: ISegment[];
  cursor?: { lastDocument: firebase.firestore.DocumentSnapshot };
}> {
  const project = await _getAuthorizedProject(projectId, uid);
  let query = project
    .collection(`topics/${projectId}/segmentsMeta`)
    .where("topicId", "==", topicId)
    .limit(10);

  if (!includeInterviewer) {
    query = query.where("interviewer", "==", false);
  }

  if (interviewsFilter != null && interviewsFilter.interviews.length > 0) {
    query = query.where(
      "interview",
      interviewsFilter.op === "exclude" ? "not-in" : "in",
      interviewsFilter.interviews.map((p) => p.id)
    );
    if (interviewsFilter.op === "exclude") {
      query = query.orderBy("interview");
    }
  }

  if (topicScoreThreshold != null && topicScoreThreshold > 0) {
    query = query.where(
      "score",
      ">=",
      // @ts-ignore topicScoreThreshold could be passed as a string regardless of what TS says about // // its type and string value changes the results of the query.
      parseFloat(topicScoreThreshold)
    );
  }

  query = query.orderBy(orderBy, ascOrDesc);

  if (lastDocument != null) {
    query = query.startAfter(lastDocument);
  }

  return query.get().then((qss) => {
    if (qss.size === 0) return { segments: [] };
    const docIds = qss.docs.reduce<{
      [id: string]: firebase.firestore.DocumentSnapshot;
    }>((c, v) => {
      c[v.get("segmentId")] = v;
      return c;
    }, {});
    console.log(qss.docs.map((doc) => doc.get("score")));
    return project
      .collection("segments")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "in",
        Object.keys(docIds)
      )
      .get()
      .then((qss) => {
        const segments = qss.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
              metaId: docIds[doc.id].id,
              score: docIds[doc.id].get("score"),
            } as ISegment)
        );
        return {
          segments,
          cursor: { lastDocument: docIds[qss.docs[qss.size - 1].id] },
        };
      });
  });
}

export async function getSegments(
  projectId: string,
  uid: string,
  limit: number = LIMIT,
  includeInterviewer: boolean = false,
  interviewsFilter?: InterviewsFilterType,
  topicId?: string,
  topicScoreThreshold?: number,
  orderBy: string = "updatedAt",
  ascOrDesc: firebase.firestore.OrderByDirection = "asc",
  lastDocument?: firebase.firestore.DocumentSnapshot
): Promise<firebase.firestore.DocumentSnapshot[]> {
  const project = await _getAuthorizedProject(projectId, uid);
  let query = project.collection("segments").limit(limit);

  if (!includeInterviewer) {
    query = query.where("interviewer", "==", false);
  }

  if (interviewsFilter != null && interviewsFilter.interviews.length > 0) {
    query = query.where(
      "interview",
      interviewsFilter.op === "exclude" ? "not-in" : "in",
      interviewsFilter.interviews.map((p) => p.id)
    );
    if (interviewsFilter.op === "exclude") {
      query = query.orderBy("interview");
    }
  }
  if (topicId != null) {
    query = query.where("topicIds", "array-contains", topicId);
  }
  // if (topicScoreThreshold != null && topicScoreThreshold > 0) {
  //   query = query.where(
  //     "topic_score",
  //     ">=",
  //     // @ts-ignore topicScoreThreshold could be passed as a string regardless of what TS says about // // its type and string value changes the results of the query.
  //     parseFloat(topicScoreThreshold)
  //   );
  // }
  query = query.orderBy(orderBy, ascOrDesc);
  if (lastDocument != null) {
    query = query.startAfter(lastDocument);
  }
  return query.get().then((qss) => qss.docs);
}

export async function addTopicToSegment(
  projectId: string,
  uid: string,
  id: string,
  topicId: string
): Promise<ISegment> {
  const project = await _getAuthorizedProject(projectId, uid);
  const segment = await project.collection("segments").doc(id).get();
  const user_topicIds = new Set(segment.get("user_topicIds") ?? []);
  user_topicIds.add(topicId);
  return segment.ref
    .update({
      user_topicIds: Array.from(user_topicIds),
    })
    .then((_) =>
      segment.ref
        .get()
        .then((doc) => ({ ...doc.data(), id: doc.id } as ISegment))
    );
}

export async function removeTopicFromSegment(
  projectId: string,
  uid: string,
  id: string,
  topicId: string
): Promise<ISegment> {
  const project = await _getAuthorizedProject(projectId, uid);
  const segment = await project.collection("segments").doc(id).get();
  const user_topicIds = new Set(segment.get("user_topicIds") ?? []);
  user_topicIds.delete(topicId);
  return segment.ref
    .update({
      user_topicIds: Array.from(user_topicIds),
    })
    .then((_) =>
      segment.ref
        .get()
        .then((doc) => ({ ...doc.data(), id: doc.id } as ISegment))
    );
}

export async function formatTopicsOfSegments(
  projectId: string,
  uid: string
): Promise<void> {
  const project = await _getAuthorizedProject(projectId, uid);
  const query = project.collection("segments").limit(LIMIT);
  let lastDoc = null;
  while (true) {
    let q: firebase.firestore.Query<firebase.firestore.DocumentData> =
      lastDoc == null ? query : query.startAfter(lastDoc);
    const qss = await q.get();
    if (qss.empty) break;
    if (qss.size <= 0) break;
    lastDoc = qss.docs[qss.size - 1];
    console.log(qss.docs.map((d) => d.id));
    for (const doc of qss.docs) {
      const topics: { [id: string]: any } | null | undefined =
        doc.get("topics");
      await doc.ref.update({
        topicIds: topics == null ? [] : Object.keys(topics),
      });
    }
  }
}

async function clearCollection(coll: firebase.firestore.CollectionReference) {
  const existingMetadata = await coll.get();
  const sliced = [];
  const CHUNK_SIZE = 20;
  for (let i = 0; i < Math.ceil(existingMetadata.size / CHUNK_SIZE); i++) {
    sliced.push(
      existingMetadata.docs.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE)
    );
  }
  for (let docs of sliced) {
    const batch = firebase.firestore().batch();
    docs.forEach((doc) => batch.delete(doc.ref));
    await batch.commit();
  }
}

export async function formatScoresOfSegments(
  projectId: string,
  uid: string
): Promise<void> {
  const project = await _getAuthorizedProject(projectId, uid);
  const query = project.collection("segments").limit(LIMIT);
  const coll = project.collection(`topics/${projectId}/segmentsMeta`);
  await clearCollection(coll);

  let lastDoc = null;
  while (true) {
    let q: firebase.firestore.Query<firebase.firestore.DocumentData> =
      lastDoc == null ? query : query.startAfter(lastDoc);
    const qss = await q.get();
    if (qss.empty) break;
    if (qss.size <= 0) break;
    lastDoc = qss.docs[qss.size - 1];
    const batch = firebase.firestore().batch();
    for (const doc of qss.docs) {
      const topics: { [id: string]: any } | null | undefined =
        doc.get("topics");
      if (topics == null) continue;
      Object.keys(topics).forEach((topicId) =>
        batch.set(coll.doc(), {
          topicId,
          segmentId: doc.id,
          score: topics[topicId],
          updatedAt: doc.get("updatedAt"),
          interviewer: doc.get("interviewer"),
          interview: doc.get("interview"),
        })
      );
    }
    await batch.commit();
  }
}

export async function cleanUpStaleSegments(
  projectId: string,
  uid: string
): Promise<void> {
  const project = await _getAuthorizedProject(projectId, uid);
  const validInterviews = await project
    .get()
    .then((dss) => dss.get("interviews"));
  if (validInterviews == null || validInterviews.length === 0) return;
  const validInterviewIds = validInterviews.reduce((c: any, iv: any) => {
    c[iv.interviewRef.id] = true;
    return c;
  }, {});

  const query = project.collection("segments").limit(LIMIT);
  let lastDoc: firebase.firestore.DocumentSnapshot | null = null;
  let totalDeleted = 0;
  while (true) {
    const q: firebase.firestore.Query =
      lastDoc === null ? query : query.startAfter(lastDoc);
    const qss = await q.get();
    if (qss.empty || qss.size <= 0) break;
    lastDoc = qss.docs[qss.size - 1];
    const segmentsToDelete = qss.docs.filter(
      (doc) => validInterviewIds[doc.get("interview")] == null
    );
    const batch = firebase.firestore().batch();
    segmentsToDelete.forEach((s) => batch.delete(s.ref));
    totalDeleted += segmentsToDelete.length;
    await batch.commit();
  }
  console.log("Stale Segments deleted: ", totalDeleted);
  return;
}

export async function getProjectStatus(
  projectId: string,
  uid: string
): Promise<StatusRecord> {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("status")
    .doc(projectId)
    .get()
    .then((dss) => dss.data() as StatusRecord);
}

export async function observeProjectStatus(
  projectId: string,
  uid: string,
  listener: (status: StatusRecord) => void
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("status")
    .doc(projectId)
    .onSnapshot((dss) => {
      listener(dss.data() as StatusRecord);
    });
}

export async function observeQuestions(
  projectId: string,
  uid: string,
  listener: (questions: IQuestion[]) => void
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project
    .collection("questions")
    .orderBy("order", "asc")
    .onSnapshot((qss) => {
      listener(qss.docs.map((d) => ({ ...d.data(), id: d.id } as IQuestion)));
    });
}

export async function updateQuestion(
  projectId: string,
  uid: string,
  question: IQuestion,
  displayName: string
) {
  const project = await _getAuthorizedProject(projectId, uid);
  return project.collection("questions").doc(question.id).update({
    displayName,
  });
}
