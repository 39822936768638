import { Component, ErrorInfo, PropsWithChildren } from "react";
import styles from "./ErrorBoundary.module.scss";

export class ErrorBoundary extends Component<
  PropsWithChildren<unknown>,
  { error: Error | null; info: ErrorInfo | null }
> {
  constructor(props: PropsWithChildren<unknown>) {
    super(props);
    this.state = { error: null, info: null };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Display fallback UI
    this.setState({ error, info });
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.error === null) {
      return this.props.children;
    } else {
      return (
        <div className={styles.globalErrorContainer}>
          <div className={styles.globalError}>
            <div>
              {this.state.error.message}
              <br />
              <p>{this.state.error.stack}</p>
            </div>
          </div>
          <button className="button primary" onClick={() => {
            window.location.href = "/"
          }}>Go Back to Projects</button>
        </div>
      );
    }
  }
}
