import React, { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { IProject } from "../lib/types";
import * as firestore from "../lib/firestore";
import { analyzeProject } from "../lib/quallyApi";
import { UserContext } from "./UserContext";

type ProjectContextType = {
  project: IProject | null;
  updateProject: (project: Partial<IProject>) => Promise<any>;
  startAnalyzeProject: () => Promise<any>;
};

export const ProjectContext = React.createContext<ProjectContextType>({
  project: null,
  updateProject: () => {
    throw new Error("Something is wrong with ProjectContext");
  },
  startAnalyzeProject: () =>
    Promise.reject("Something is wrong with ProjectContext"),
});

export function ProjectContextProvider(props: React.PropsWithChildren<any>) {
  const { uid, touchProject } = useContext(UserContext);
  const { projectId } = useParams<{ projectId: string }>();

  const updateProject = useCallback(
    (project: Partial<IProject>) => {
      if (uid === null) return Promise.resolve();
      return firestore.updateProject(project, uid).catch(console.error);
    },
    [uid]
  );

  const [project, setProject] = useState<IProject | null>(null);

  const waitUntilProjectIsReadyToAnalyze = (projectId: string, uid: string) =>
    new Promise<void>((resolve, reject) => {
      let unsubscribe: () => void = () =>
      console.error(
        "Unsubscriber function was not registered: ProjectContext#waitUntilProjectIsReadyToAnalyze"
      );
      return firestore.observeProject(projectId, uid, (project) => {
        const timeout = setTimeout(() => {
          unsubscribe();
          reject();
        }, 10 * 60 * 1000);
        if ((project?.interviews_count ?? 0) > 0) {
          clearTimeout(timeout);
          unsubscribe();
          resolve();
        }
      }).then(u => unsubscribe = u);
    });

  const startAnalyzeProject = useCallback(async () => {
    if (project === null || uid === null) return Promise.resolve();
    await waitUntilProjectIsReadyToAnalyze(project.id, uid);
    return analyzeProject(project.id).catch(console.error);
  }, [project, uid]);

  useEffect(() => {
    if (uid === null) return;
    let unsubscribe: () => void = () =>
    console.error(
      "Unsubscriber function was not registered: ProjectContext"
    );
    firestore.observeProject(projectId, uid, (project) => {
      touchProject(projectId, uid).then(() => 
      setProject(project)).catch(console.error);
    }).then(u => unsubscribe = u);
    return () => unsubscribe();
  }, [projectId, uid, touchProject]);

  const ctx = { project, updateProject, startAnalyzeProject };
  return (
    <ProjectContext.Provider value={ctx}>
      {props.children}
    </ProjectContext.Provider>
  );
}
