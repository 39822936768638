import firebase from "firebase/app";
import { AuthEmission } from "@react-firebase/auth/dist/types";
import "firebase/auth";

export function isAuthorizedUser(authResult: AuthEmission) {
  return (
    authResult.isSignedIn && authResult.providerId === "google.com"
  );
}

export function signInWithGoogle() {
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(googleAuthProvider);
}

export function signOut() {
  return firebase.auth().signOut();
}