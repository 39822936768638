import panelStyles from "./Panel.module.scss";
import LeftNav from "../components/LeftNav";
import MainNav from "../components/MainNav";
import Favorites from "./Favorites";

export default function FavoritesPanel() {
  return (
    <div className={panelStyles.twoColumnsPanel}>
      <MainNav className={panelStyles.mainNav} />
      <LeftNav className={panelStyles.leftColumn} />
      <Favorites className={panelStyles.mainColumn} />
    </div>
  );
}
