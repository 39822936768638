import { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ProjectContext } from "../contexts/ProjectContext";
import { TopicsContext } from "../contexts/TopicsContext";
import { EMPTY_TOPIC, IProject, ITopic } from "../lib/types";
import SingleTextInputForm from "../components/SingleTextInputForm";
import styles from "./Topics.module.scss";
import topic48 from "../images/topic-fill-gray5-48.svg";
import topic24 from "../images/topic-fill-gray5-24.svg";
import { useQueryParams } from "../components/LeftNav";

export default function Topics(props: { className?: string }) {
  const { topics } = useContext(TopicsContext);

  return (
    <div
      className={`${props.className} ${styles.topics}${
        topics.length === 0 ? ` ${styles.empty}` : ""
      }`}
    >
      <SubNav />
      {topics.length > 0 ? <TopicCards /> : <EmptyTopics />}
    </div>
  );
}

function SubNav() {
  return (
    <div className={styles.subnav}>
      <h1 className={styles.heading}>Topics</h1>
      <h4 className={styles.subheading}>
        Qually analyzed your interviews and found these conversation topics
      </h4>
    </div>
  );
}

function TopicCards() {
  const { topics, updateLabel } = useContext(TopicsContext);

  return (
    <div className={styles.topicCards}>
      {topics.map((topic) => (
        <TopicCard
          key={topic.topicId}
          topic={topic}
          onUpdateDisplayName={updateLabel}
        />
      ))}
      <TopicsFooter />
    </div>
  );
}

function TopicCard(props: {
  topic: ITopic;
  onUpdateDisplayName: (topic: ITopic, name: string) => void;
}) {
  const { project } = useContext(ProjectContext);
  const [isRenaming, setRenaming] = useState<boolean>(false);
  const onSaveDisplayName = useCallback(
    (name: string) => {
      props.onUpdateDisplayName(props.topic, name);
      setRenaming(false);
    },
    [props]
  );

  return (
    <div
      className={`${styles.topicCard}${isRenaming ? ` ${styles.active}` : ""}`}
    >
      {isRenaming ? (
        <SingleTextInputForm
          className={styles.renameTopicForm}
          defaultValue={props.topic.name}
          onCancelForm={() => setRenaming(false)}
          onSaveForm={onSaveDisplayName}
        />
      ) : (
        <TopicCardHead
          project={project}
          topic={props.topic}
          onRename={() => setRenaming(true)}
        />
      )}
      {props.topic.topicId === EMPTY_TOPIC.topicId ? null : (
        <div className={styles.topicCardContent}>
          <span className={`text-italic ${styles.topicTagsCaption}`}>
            Based on these keywords from your interview guide
          </span>
          <div className={styles.topicTags}>
            {props.topic.keywords.map((keyword) => (
              <span key={keyword} className={`keyword ${styles.topicTag}`}>
                {keyword}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function TopicCardHead(props: {
  project: IProject | null;
  topic: ITopic;
  onRename: () => void;
}) {
  return (
    <div className={styles.topicCardHead}>
      <h2>{props.topic.name}</h2>
      <span className="text">{`${props.topic.segment_count ?? 0} Quotes`}</span>
      <div className={styles.controls}>
        {props.topic.topicId === EMPTY_TOPIC.topicId ? null : (
          <button
            className={`linkButton ${styles.renameButton}`}
            onClick={props.onRename}
          >
            Rename
          </button>
        )}
        <Link
          className={`button secondary ${styles.seeTopicButton}`}
          to={`/projects/${props.project?.id}/topics/${props.topic.topicId}`}
        >
          See Topic
        </Link>
      </div>
    </div>
  );
}

function TopicsFooter() {
  const { project } = useContext(ProjectContext);
  const { score } = useQueryParams();

  return (
    <div className={styles.topicsFooter}>
      <h4>Need to review all Qually topics again?</h4>
      <Link
        className={`button secondary`}
        to={`/projects/${project?.id}/topics/review?score=${score.toFixed(1)}`}
      >
        Review Topics
      </Link>
    </div>
  );
}

function EmptyTopics() {
  const { project } = useContext(ProjectContext);

  return (
    <div className={styles.emptyTopics}>
      <div className={styles.topicIcons}>
        <img src={topic24} className={`${styles.topic16}`} alt="topic 16" />
        <img src={topic24} className={`${styles.topic24}`} alt="topic 24" />
        <img src={topic48} className={`${styles.topic48}`} alt="topic 48" />
      </div>
      <h2>No Topics Yet</h2>
      <h4>
        Complete the{" "}
        <Link to={`/projects/${project?.id}`} className="link">
          set-up checklist
        </Link>{" "}
        in project settings. Then, you can run Qually's topic-finding algorithm.
      </h4>
    </div>
  );
}
