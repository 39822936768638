import { useState, useCallback, useContext, MouseEvent } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { ToastContext } from "../contexts/ToastContext";
import { TopicsContext } from "../contexts/TopicsContext";
import { UserContext } from "../contexts/UserContext";
import { addFavorite, removeFavorite } from "../lib/firestore";
import { ITopic, ISegment, ISegmentWithFav, EMPTY_TOPIC } from "../lib/types";
import styles from "./Segment.module.scss";

function useToggleFavorite(segment: ISegmentWithFav) {
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const toaster = useContext(ToastContext);
  const [isFavorite, setFavorite] = useState<boolean>(segment.fav);
  const toggleFavorite = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (user == null) return;
      if (project?.id == null) return;
      if (isFavorite) {
        removeFavorite(project.id, user.uid, segment);
        setFavorite(false);
      } else {
        addFavorite(project.id, user.uid, segment);
        setFavorite(true);
        toaster.showToast("Quote added to favorites");
      }
    },
    [user, project, segment, toaster, isFavorite]
  );
  return [isFavorite, toggleFavorite] as const;
}

export default function Segment(props: {
  segment: ISegmentWithFav;
  isSelected: boolean;
  onSelectSegment: () => void;
  onRemoveTopic: (topicId: string) => void;
  onAddTopic: (topicId: string) => void;
}) {
  const spans = Math.ceil(props.segment.text.length / 256);

  const [isFavorite, toggleFavorite] = useToggleFavorite(props.segment);
  const [isActive, setActive] = useState<boolean>(false);

  return (
    <div
      data-id={props.segment.id}
      data-score={props.segment.score}
      className={`${styles.segment}${
        props.isSelected ? ` ${styles.selected}` : ""
      }${isActive ? ` ${styles.active}` : ""}`}
      style={{ gridRowEnd: `span ${spans}` }}
      onClick={props.onSelectSegment}
    >
      <div className={`subhead ${styles.speaker}`}>
        {props.segment.displayName}
      </div>
      <div className={`text ${styles.text}`}>{props.segment.text}</div>
      <SegmentTopics
        segment={props.segment}
        onRemoveTopic={props.onRemoveTopic}
        toggleTopicList={setActive}
        addTopic={(topic) => {
          setActive(false);
          props.onAddTopic(topic.topicId);
        }}
      />
      <button
        className={`${styles.star}${isFavorite ? ` ${styles.favorite}` : ""}`}
        onClick={toggleFavorite}
      />
    </div>
  );
}

export function SegmentTopics(props: {
  segment: ISegment;
  onRemoveTopic: (topicId: string) => void;
  toggleTopicList: (active: boolean) => void;
  addTopic: (topic: ITopic) => void;
}) {
  const { topics } = useContext(TopicsContext);

  const [isAddingTopic, setAddingTopic] = useState<boolean>(false);

  const topicTags = Array.from(
    new Set([...props.segment.topicIds, ...(props.segment.user_topicIds ?? [])])
  ).reduce<ITopic[]>((c, v) => {
    const t = topics.find((t) => t.topicId === v)
    if (t != null) c.push(t)
    return c
  }, []);
  const toggleTopicList = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setAddingTopic((v) => !v);
      props.toggleTopicList(!isAddingTopic);
    },
    [isAddingTopic, props]
  );

  return (
    <div className={styles.topics}>
      {topicTags.reduce<JSX.Element[]>((c, v) => {
        if (v.topicId === EMPTY_TOPIC.id) return c;
        c.push(
          <div key={v.topicId}>
            <button
              className={`topic ${styles.segmentTopic}`}
              onClick={(e) => {
                e.stopPropagation();
                props.onRemoveTopic(v.topicId);
              }}
            >
              {v.name}
              <span className={`tooltip ${styles.removeTopicHint}`}>
                Remove Topic
              </span>
            </button>
          </div>
        );
        return c;
      }, [])}
      <div
        className={`action-container ${styles.addTagContainer}${
          isAddingTopic ? `  ${styles.addTagContainerActive}` : ""
        }`}
      >
        <button
          title="Add tag"
          onClick={toggleTopicList}
          className={`topic-action ${styles.addTagButton}${
            isAddingTopic ? " selected" : ""
          }`}
        >
          Add tag
        </button>
        <ul className={`topic-action-list ${styles.additionalTags}`}>
          <li key={-1} className={`topic-action-list-caption`}>
            Add a Topic Tag
          </li>
          {topics
            .filter(
              (t) =>
                t.topicId !== EMPTY_TOPIC.id &&
                topicTags.findIndex((tt) => tt.topicId === t.topicId) < 0
            )
            .map((topic) => (
              <li key={topic.topicId} className="topic-action-list-item">
                <button
                  onClick={(e) => {
                    toggleTopicList(e);
                    props.addTopic(topic);
                  }}
                >
                  {topic.name}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
