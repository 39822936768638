import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { Link } from "react-router-dom";
import styles from "./MainNav.module.scss";

export default function MainNav(props: { className?: string }) {
  const { project } = useContext(ProjectContext);
  return (
    <div className={`${styles.mainNav} ${props.className}`}>
      <Link to={`/projects/${project?.id}`}>
        <h2 className={styles.projectName}>{ project?.displayName ?? "" }</h2>
      </Link>
      <Link to="/">
        <div className={styles.logo} />
      </Link>
    </div>
  );
}
