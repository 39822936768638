import { useCallback, useEffect, useRef, useState } from "react";
import { IBoxFolder } from "../lib/types";
import styles from "./BoxContentPicker.module.scss";

const BOX_API_VERSION = "13.0.0";

function createScriptElement(doc: Document, src: string, onLoad?: () => void) {
  const s = document.createElement("script");
  s.src = src;
  if (!!onLoad) s.addEventListener("load", onLoad);
  return s;
}

export default function BoxContentPicker(props: {
  accessToken: string;
  rootFolderId: string;
  onCancel: () => void;
  onPickFolder: (e: IBoxFolder[]) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [, setFolderPickerObject] = useState<any>(null);

  const createRequiredElements = useCallback(() => {
    if (ref == null || ref.current == null) return;

    const link = window.document.createElement("link");
    link.rel = "stylesheet";
    link.href = `https://cdn01.boxcdn.net/platform/elements/${BOX_API_VERSION}/en-US/picker.css`;
    window.document.head.appendChild(link);

    const parent = ref?.current?.parentElement;
    parent?.appendChild(
      createScriptElement(
        window.document,
        "https://cdn.polyfill.io/v2/polyfill.min.js?features=es6,Intl"
      )
    );
    parent?.appendChild(
      createScriptElement(
        window.document,
        `https://cdn01.boxcdn.net/platform/elements/${BOX_API_VERSION}/en-US/picker.js`,
        () => {
          // @ts-ignore Box is there by now.
          const folderPicker = new window.Box.FolderPicker();
          folderPicker.addListener("cancel", props.onCancel);
          folderPicker.addListener("choose", props.onPickFolder);
          folderPicker.show(props.rootFolderId, props.accessToken, {
            container: `.${styles.boxContentPicker}`,
            maxSelectable: 1,
            cancelButtonLabel: "Close",
            chooseButtonLabel: "Select"
          });
          setFolderPickerObject(folderPicker);
        }
      )
    );
  }, [ref, props]);

  const cleanup = useCallback(() => {
    setFolderPickerObject((fp: any) => {
      fp.removeAllListeners();
      return null;
    });
  }, []);

  useEffect(() => {
    createRequiredElements();
    return cleanup;
  }, [createRequiredElements, cleanup]);

  return <div className={styles.boxContentPicker} ref={ref} />;
}
