import { useContext } from "react";
import panelStyles from "./Panel.module.scss";
import LeftNav from "../components/LeftNav";
import MainNav from "../components/MainNav";
import ProjectSettings from "./ProjectSettings";
import { ProjectContext } from "../contexts/ProjectContext";
import { ProjectStatusContext } from "../contexts/ProjectStatusContext";
import { SettingsState, settingsState } from "../lib/status";

export default function ProjectSettingsPanel() {
  const { project } = useContext(ProjectContext);
  const statusRecord = useContext(ProjectStatusContext);
  const state = settingsState(project, statusRecord);

  return (
    <div className={panelStyles.twoColumnsPanel}>
      <MainNav className={panelStyles.mainNav} />
      {state === SettingsState.ANALYZING || state === SettingsState.READY ? (
        <LeftNav className={panelStyles.leftColumn} />
      ) : (
        <EmptyLeftNav className={panelStyles.leftColumn} />
      )}
      <ProjectSettings className={panelStyles.mainColumn} />
    </div>
  );
}

function EmptyLeftNav(props: { className: string }) {
  return <div className={`${props.className}`} />;
}
