import {
  FirebaseAuthProvider,
  IfFirebaseAuthed,
  IfFirebaseUnAuthed,
} from "@react-firebase/auth";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthEmission } from "@react-firebase/auth/dist/types";
import firebase from "firebase/app";
import styles from "./App.module.scss";
import { firebaseConfig } from "./lib/firebaseApp";
import {
  isAuthorizedUser,
  signInWithGoogle,
  signOut,
} from "./lib/firebaseAuth";
import Projects from "./paths/Projects";
import { ProjectsContextProvider } from "./contexts/ProjectsContext";
import { ProjectContextProvider } from "./contexts/ProjectContext";
import { TopicsContextProvider } from "./contexts/TopicsContext";
import UserContextProvider from "./contexts/UserContext";
import FavoritesPanel from "./paths/FavoritesPanel";
import SingleTopicPanel from "./paths/SingleTopicPanel";
import GeneratedTopicsPanel from "./paths/GeneratedTopicsPanel";
import InterviewsPanel from "./paths/InterviewsPanel";
import { InterviewsContextProvider } from "./contexts/InterviewsContext";
import { BoxAuthContextProvider } from "./contexts/BoxAuthContext";
import ProjectSettingsPanel from "./paths/ProjectSettingsPanel";
import { ModalContextProvider } from "./contexts/ModalContext";
import { ToastContextProvider } from "./contexts/ToastContext";
import { useEffect, useState } from "react";
import { ProjectStatusContextProvider } from "./contexts/ProjectStatusContext";
import TopicsPanel from "./paths/TopicsPanel";
import QuestionsPanel from "./paths/QuestionsPanel";
import { QuestionsContextProvider } from "./contexts/QuestionsContext";

function renderAuthResult(authResult: AuthEmission) {
  if (isAuthorizedUser(authResult)) {
    return <AuthenticatedApp user={authResult.user} />;
  } else if (authResult.isSignedIn) {
    signOut();
    return <p>...</p>;
  } else {
    return <Login />;
  }
}

function App() {
  return (
    <div className={styles.App}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <IfFirebaseAuthed>{renderAuthResult}</IfFirebaseAuthed>
        <IfFirebaseUnAuthed>{renderAuthResult}</IfFirebaseUnAuthed>
      </FirebaseAuthProvider>
    </div>
  );
}

function AuthenticatedApp(props: { user: firebase.User }) {
  return (
    <UserContextProvider user={props.user}>
      <ProjectsContextProvider>
        <ToastContextProvider>
          <Router>
            <Switch>
              <Route path="/projects/:projectId" component={ProjectRoute} />
              <Route path="/projects">
                <Projects />
              </Route>
              <Route path="/">
                <Redirect to="/projects" />
              </Route>
            </Switch>
          </Router>
        </ToastContextProvider>
      </ProjectsContextProvider>
    </UserContextProvider>
  );
}

function ProjectRoute(props: any) {
  return (
    <ModalContextProvider>
      <BoxAuthContextProvider>
        <ProjectContextProvider>
          <ProjectStatusContextProvider>
            <TopicsContextProvider>
              <InterviewsContextProvider>
                <QuestionsContextProvider>
                  <Router>
                    <Switch>
                      <Route path="/projects/:projectId/interviews">
                        <InterviewsPanel />
                      </Route>
                      <Route path="/projects/:projectId/topics/review">
                        <GeneratedTopicsPanel />
                      </Route>
                      <Route path="/projects/:projectId/topics/:topicId">
                        <SingleTopicPanel />
                      </Route>
                      <Route path="/projects/:projectId/favorites">
                        <FavoritesPanel />
                      </Route>
                      <Route path="/projects/:projectId/topics">
                        <TopicsPanel />
                      </Route>
                      <Route path="/projects/:projectId/questions">
                        <QuestionsPanel />
                      </Route>
                      <Route path="/projects/:projectId">
                        <ProjectSettingsPanel />
                      </Route>
                    </Switch>
                  </Router>
                </QuestionsContextProvider>
              </InterviewsContextProvider>
            </TopicsContextProvider>
          </ProjectStatusContextProvider>
        </ProjectContextProvider>
      </BoxAuthContextProvider>
    </ModalContextProvider>
  );
}

function Login() {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    requestAnimationFrame(() => setLoaded(true));
  });

  return (
    <div className={`${styles.appRoot}${loaded ? ` ${styles.loaded}` : ""}`}>
      <div className={styles.mainNav}>
        <div className={styles.logo} />
      </div>
      <div className={styles.appContent}>
        <button
          onClick={signInWithGoogle}
          className={styles.signinButton}
          title="Sign in with your Google account"
        />
      </div>
    </div>
  );
}

export default App;
