import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { observeQuestions, updateQuestion } from "../lib/firestore";
import { IQuestion } from "../lib/types";
import { ProjectContext } from "./ProjectContext";
import { UserContext } from "./UserContext";

type QuestionsContextType = {
  questions: IQuestion[];
  updateDisplayName: (question: IQuestion, name: string) => void;
};

export const QuestionsContext = createContext<QuestionsContextType>({
  questions: [],
  updateDisplayName: () => { throw new Error("QuestionsContext is not prepared yet") }
});

export function QuestionsContextProvider(props: PropsWithChildren<unknown>) {
  const { uid } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const updateLabel = useCallback(
    (question: IQuestion, name: string) => {
      if (project === null || uid === null || question.id == null) return;
      updateQuestion(project.id, uid, question, name);
      setQuestions((questions) => {
        const i = questions.findIndex((q) => q.id === question.id);
        if (i < 0) return questions;
        const newQuestions = [...questions];
        newQuestions.splice(i, 1, { ...question, displayName: name });
        return newQuestions;
      });
    },
    [project, uid, setQuestions]
  );

  useEffect(() => {
    if (project == null || uid === null) return;
    let unsubscribe: () => void = () => console.error("Unsubscriber function was not registered: QuestionsContext")
    observeQuestions(project.id, uid, setQuestions)
      .then((u) => unsubscribe = u)
      .catch(console.error);
    return () => unsubscribe();
  }, [uid, project])

  return <QuestionsContext.Provider value={{ questions, updateDisplayName: updateLabel }}>
    {props.children}
  </QuestionsContext.Provider>
}