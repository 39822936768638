import { useCallback, useContext, useState } from "react";
import { InterviewsContext } from "../contexts/InterviewsContext";
import { IInterviewDetail, INTERVIEWER } from "../lib/types";
import styles from "./Interviews.module.scss";
import toggleButtonStyles from "./ToggleButton.module.scss";
import { ProjectContext } from "../contexts/ProjectContext";
import { Link } from "react-router-dom";
import people48 from "../images/people-fill-gray5-48.svg";
import people24 from "../images/people-fill-gray5-24.svg";
import people18 from "../images/people-fill-gray5-18.svg";
import SingleTextInputForm from "../components/SingleTextInputForm";

export default function Interviews(props: {
  className: string;
  onSelectInterview: (interview: IInterviewDetail) => void;
}) {
  const [selectedInterview, setSelectedInterview] = useState<IInterviewDetail | null>(null);
  const {
    interviews,
    excludeInterviews,
    setExcludeInterviews,
    includeInterviewer,
    setIncludeInterviewer,
    addTag,
    removeTag,
    updateName,
  } = useContext(InterviewsContext);

  const onToggleIncludeInterview = useCallback(
    (interview: IInterviewDetail, show: boolean) => {
      if (show) {
        setExcludeInterviews([...excludeInterviews].filter((x) => x.id !== interview.id));
      } else {
        const h = excludeInterviews.reduce<{[id:string]: IInterviewDetail}>((c, v) => {
          c[v.id] = v
          return c
        }, {})
        h[interview.id] = interview
        setExcludeInterviews(Object.values(h));
      }
    },
    [excludeInterviews, setExcludeInterviews]
  );

  const onToggleInterviewer = (interview: IInterviewDetail, show: boolean) =>
    setIncludeInterviewer(show);

  const allTags = interviews.reduce<Set<string>>((c, v) => {
    if (v.tags == null) return c;
    Object.keys(v.tags)
      .filter((tag) => v.tags[tag])
      .forEach((tag) => c.add(tag));
    return c;
  }, new Set());

  return (
    <div
      className={`${styles.interviews} ${props.className}${
        interviews.length === 0 ? ` ${styles.empty}` : ""
      }`}
    >
      <SubNav numInterviews={interviews.length} />
      {interviews.length > 0 ? (
        <>
          <Interview
            className={styles.interviewer}
            allTags={allTags}
            interview={INTERVIEWER}
            includeInterview={includeInterviewer}
            isSelected={false}
            onToggleIncludeInterview={onToggleInterviewer}
            onSelect={() => {}}
            onAddTag={() => {}}
            onRemoveTag={() => {}}
            onUpdateName={() => {}}
          />
          {interviews.map((interview) => (
            <Interview
              key={interview.id}
              allTags={allTags}
              interview={interview}
              includeInterview={isInterviewIncluded(interview, excludeInterviews)}
              isSelected={selectedInterview?.id === interview.id}
              onToggleIncludeInterview={onToggleIncludeInterview}
              onSelect={() => {
                setSelectedInterview(interview);
                props.onSelectInterview(interview);
              }}
              onAddTag={(tag) => addTag(interview.id, tag)}
              onRemoveTag={(tag) => removeTag(interview.id, tag)}
              onUpdateName={(name) => updateName(interview.id, name)}
            />
          ))}
        </>
      ) : (
        <EmptyInterviews />
      )}
    </div>
  );
}

function isInterviewIncluded(interview: IInterviewDetail, excludeInterviews: IInterviewDetail[]) {
  return excludeInterviews.findIndex((iv) => iv.id === interview.id) < 0;
}

function SubNav(props: { numInterviews: number }) {
  return (
    <div className={styles.subnav}>
      <h1>Interviews</h1>
      <span className={`numberBadge dark ${styles.numInterviews}`}>
        {props.numInterviews}
      </span>
      <span className={`subhead ${styles.toggleCaption}`}>
        Include in results
      </span>
    </div>
  );
}

function Interview(props: {
  className?: string;
  allTags: Set<string>;
  interview: IInterviewDetail;
  includeInterview: boolean;
  isSelected: boolean;
  onToggleIncludeInterview: (interview: IInterviewDetail, include: boolean) => void;
  onSelect: () => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (tag: string) => void;
  onUpdateName: (name: string) => void;
}) {
  const [isAddingTag, setAddingTag] = useState<boolean>(false);
  const tags = Object.keys(props.interview.tags).filter(
    (tag) => props.interview.tags[tag]
  );
  const [isRenaming, setRenaming] = useState<boolean>(false);
  const onSaveName = useCallback(
    (name: string) => {
      props.onUpdateName(name);
      setRenaming(false);
    },
    [props]
  );

  return (
    <div
      className={`${styles.interview}${
        props.isSelected ? ` ${styles.selected}` : ""
      }${isAddingTag ? ` ${styles.active}` : ""}${
        props.className == null ? "" : ` ${props.className}`
      }`}
      onClick={() => props.onSelect()}
    >
      {isRenaming ? (
        <SingleTextInputForm
          defaultValue={props.interview.displayName}
          onCancelForm={() => setRenaming(false)}
          onSaveForm={onSaveName}
        />
      ) : (
        <div className={styles.interviewHead}>
          <h2 className={styles.displayName}>{props.interview.displayName}</h2>
          <div className={styles.controls}>
            <button
              className={`linkButton ${styles.renameButton}`}
              onClick={(e) => {
                e.stopPropagation();
                setRenaming(true);
              }}
            >
              Rename
            </button>
            <ToggleButton {...props} />
          </div>
        </div>
      )}
      <div className={styles.interviewTags}>
        <div className={`subhead ${styles.tagsCaption}`}>Tags</div>
        <div className={styles.tags}>
          {tags.map((tag) => (
            <button
              key={tag}
              title={tag}
              className={`cohort ${styles.tag}`}
              onClick={(e) => {
                e.stopPropagation();
                props.onRemoveTag(tag);
              }}
            >
              {tag}
              <span className={`tooltip ${styles.removeTagHint}`}>
                Remove Tag
              </span>
            </button>
          ))}
          <div
            className={`action-container ${styles.addTagContainer}${
              isAddingTag ? `  ${styles.addTagContainerActive}` : ""
            }`}
          >
            <button
              title="Add tag"
              className={`cohort-action ${styles.addTagButton}${
                isAddingTag ? " selected" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setAddingTag((at) => !at);
              }}
            >
              Add tag
            </button>
            <ul className={`cohort-action-list ${styles.additionalTags}`}>
              <li key={-1} className={`cohort-action-list-caption`}>
                Add a Interviews Tag
              </li>
              {Array.from(props.allTags)
                .filter((tag) => tags.findIndex((t) => t === tag) < 0)
                .map((pt) => (
                  <li key={pt} className="cohort-action-list-item">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onAddTag(pt);
                        setAddingTag(false);
                      }}
                    >
                      {pt}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function ToggleButton(props: {
  interview: IInterviewDetail;
  includeInterview: boolean;
  onToggleIncludeInterview: (interview: IInterviewDetail, include: boolean) => void;
}) {
  return (
    <>
      <input
        type="checkbox"
        id={`toggle-${props.interview.id}`}
        className={`subhead ${toggleButtonStyles.toggle}`}
        defaultChecked={props.includeInterview}
        onChange={(e) => {
          e.stopPropagation();
          props.onToggleIncludeInterview(props.interview, e.currentTarget.checked);
        }}
      />
      <label
        className={`${toggleButtonStyles.toggleButton} ${styles.toggleButton}`}
        htmlFor={`toggle-${props.interview.id}`}
      />
      <span className={`tooltip ${styles.toggleHint}`}>
        {props.includeInterview
          ? "Included in Results"
          : "Not included in results"}
      </span>
    </>
  );
}

function EmptyInterviews() {
  const { project } = useContext(ProjectContext);

  return (
    <div className={styles.emptyInterviews}>
      <div className={styles.peopleIcons}>
        <img src={people18} className={`${styles.people18}`} alt="people 18" />
        <img src={people24} className={`${styles.people24}`} alt="people 24" />
        <img src={people48} className={`${styles.people48}`} alt="people 48" />
      </div>
      <h2>No Interviews Yet</h2>
      <h4>
        Complete the{" "}
        <Link to={`/projects/${project?.id}`} className="link">
          set-up checklist
        </Link>{" "}
        in project settings.
        <br />
        Then, you can run Qually’s topic-finding algorithm.
      </h4>
    </div>
  );
}
