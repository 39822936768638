import { useCallback, useContext, useState } from "react";
import { ModalContext } from "../contexts/ModalContext";
import { IInterviewTheme } from "../lib/types";
import styles from "./InterviewGuideEditor.module.scss";

function isValidInterviewGuide(
  guide: IInterviewTheme[] | null | undefined
): boolean {
  if (guide == null) return false;
  if (guide.length === 0) return false;
  for (const t of guide) {
    if (t.theme == null || t.theme.length === 0) return false;
    if (t.questions == null || t.questions.length === 0) return false;
    for (const q of t.questions) {
      if (q == null || q.length === 0) return false;
    }
  }
  return true;
}

export default function useModalInterviewGuideEditor(
  onSaveGuide: (guide: IInterviewTheme[]) => void
) {
  const modal = useContext(ModalContext);
  const onCancel = useCallback(() => {
    modal.closeModal();
  }, [modal]);

  const onSave = useCallback(
    (guide: IInterviewTheme[]) => {
      if (!isValidInterviewGuide(guide)) return;
      onSaveGuide(guide);
      modal.closeModal();
    },
    [modal, onSaveGuide]
  );

  const showModalEditor = useCallback(
    (interviewGuide: IInterviewTheme[] | undefined) => {
      modal.showModal(
        <InterviewGuideEditor
          interviewGuide={interviewGuide}
          onCancel={onCancel}
          onSave={onSave}
        />
      );
    },
    [modal, onCancel, onSave]
  );

  return showModalEditor;
}

const EMPTY_THEME: IInterviewTheme = {
  theme: "",
  questions: [""],
  description: "",
};

function InterviewGuideEditor(props: {
  interviewGuide: IInterviewTheme[] | undefined;
  onCancel: () => void;
  onSave: (guide: IInterviewTheme[]) => void;
}) {
  const [isValid, setValid] = useState<boolean>(false);

  const [guide, setGuide] = useState<IInterviewTheme[]>([
    ...((props.interviewGuide ?? []).length === 0
      ? [EMPTY_THEME]
      : props.interviewGuide!),
  ]);
  const updateTheme = useCallback((i: number, t: IInterviewTheme) => {
    setGuide((g) => {
      const newGuide = [...g];
      newGuide.splice(i, 1, t);
      setValid(isValidInterviewGuide(newGuide));
      return newGuide;
    });
  }, []);
  const deleteTheme = useCallback((i) => {
    setGuide((g) => {
      const newGuide = [...g];
      newGuide.splice(i, 1);
      if (newGuide.length === 0) {
        newGuide.push(EMPTY_THEME);
      }
      setValid(isValidInterviewGuide(newGuide));
      return newGuide;
    });
  }, []);
  const addTheme = useCallback(() => {
    setGuide((g) => {
      const newGuide = [...g];
      newGuide.push(EMPTY_THEME);
      setValid(isValidInterviewGuide(newGuide));
      return newGuide;
    });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.scrollingContainer}>
        <div className={styles.interviewGuideEditor}>
          {guide.map((theme, i) => (
            <InterviewThemeEditor
              key={i}
              theme={theme}
              updateTheme={(t) => updateTheme(i, t)}
              deleteTheme={() => deleteTheme(i)}
            />
          ))}
          <div className={styles.guideControls}>
            <button className={`button secondary`} onClick={addTheme}>
              Add a theme
            </button>
          </div>
        </div>
      </div>
      <div className={styles.dialogControls}>
        <button className={`button secondary`} onClick={props.onCancel}>
          Cancel
        </button>
        <button
          className={`button primary`}
          disabled={!isValid}
          onClick={() => props.onSave(guide)}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function InterviewThemeEditor(props: {
  theme: IInterviewTheme;
  updateTheme: (theme: IInterviewTheme) => void;
  deleteTheme: () => void;
}) {
  const { updateTheme, deleteTheme } = props;

  const [theme, setTheme] = useState<IInterviewTheme>({
    ...props.theme,
    questions: [
      ...((props.theme.questions ?? []).length === 0
        ? [""]
        : props.theme.questions),
    ],
  });

  const updateQuestion = useCallback(
    (i: number, q: string) => {
      const qs = [...theme.questions];
      if (q == null || q.length === 0) {
        qs.splice(i, 1);
        if (qs.length === 0) {
          qs.push("");
        }
      } else {
        qs.splice(i, 1, q);
      }
      const newTheme = { ...theme, questions: qs };
      setTheme(newTheme);
      updateTheme(newTheme);
    },
    [theme, updateTheme]
  );

  const addQuestion = useCallback(() => {
    const qs = [...(theme.questions ?? [])];
    qs.push("");
    const newTheme = { ...theme, questions: qs };
    setTheme(newTheme);
    updateTheme(newTheme);
  }, [theme, updateTheme]);

  const deleteQuestion = useCallback(
    (i: number) => {
      const qs = [...(theme.questions ?? [])];
      qs.splice(i, 1);
      if (qs.length === 0) {
        qs.push("");
      }
      const newTheme = { ...theme, questions: qs };
      setTheme(newTheme);
      updateTheme(newTheme);
    },
    [theme, updateTheme]
  );

  const editTheme = useCallback(
    (key: string, value: any) => {
      const newTheme = { ...theme };
      // @ts-ignore key must be valid
      newTheme[key] = value;
      setTheme(newTheme);
      updateTheme(newTheme);
    },
    [theme, updateTheme]
  );

  return (
    <div className={styles.interviewThemeEditor}>
      <div className={styles.themeInput}>
        <input
          type="text"
          placeholder="Enter a label"
          defaultValue={theme.theme}
          onChange={(e) => editTheme("theme", e.target.value)}
        />
      </div>
      <ul className={styles.questions}>
        {theme.questions.map((q, i) => (
          <Question
            key={i}
            question={q}
            updateQuestion={(q) => updateQuestion(i, q)}
            deleteQuestion={() => deleteQuestion(i)}
          />
        ))}
        <li className={styles.questionsControls}>
          <button className={`button secondary`} onClick={addQuestion}>
            Add a question
          </button>
        </li>
      </ul>
      <div className={styles.themeControls}>
        <button onClick={deleteTheme} className={`button secondary`}>
          Delete Theme
        </button>
      </div>
    </div>
  );
}

function Question(props: {
  question: string;
  updateQuestion: (q: string) => void;
  deleteQuestion: () => void;
}) {
  return (
    <li className={styles.question}>
      <input
        type="text"
        placeholder="Enter a question"
        defaultValue={props.question}
        onChange={(e) => props.updateQuestion(e.target.value)}
      />
      <div className={styles.questionControls}>
        <button
          onClick={props.deleteQuestion}
          className={styles.deleteQuestionButton}
        />
      </div>
    </li>
  );
}
