import { useCallback, useEffect, useRef, useState, MouseEvent } from "react";
import styles from "./SingleTextInputForm.module.scss";

export default function SingleTextInputForm(props: {
  className?: string;
  defaultValue: string;
  onSaveForm: (name: string) => void;
  onCancelForm: () => void;
}) {
  const [name, setName] = useState<string>(props.defaultValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onCancel = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onCancelForm();
    setName("");
  }, [setName, props]);

  const onSave = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (name.length === 0) return;
    props.onSaveForm(name);
    setName("");
  }, [name, setName, props]);

  const clearInput = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setName("");
    if (inputRef.current !== null) inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    if (inputRef.current !== null) inputRef.current.focus();
  }, [inputRef]);

  return (
    <div className={`${styles.form} ${props.className}`}>
      <input
        ref={inputRef}
        type="text"
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />
      <button className={styles.clearInputButton} onClick={clearInput} />
      <div className={styles.formControls}>
        <button className={`linkButton primary ${styles.cancelButton}`} onClick={onCancel}>
          Cancel
        </button>
        <button
          className={`button primary ${styles.saveButton}`}
          onClick={onSave}
          disabled={name.length === 0 || name === props.defaultValue}
        >
          Save
        </button>
      </div>
    </div>
  );
}
