import panelStyles from "./Panel.module.scss";
import LeftNav from "../components/LeftNav";
import MainNav from "../components/MainNav";
import Questions from "./Questions";

export default function QuestionsPanel() {

  return (
    <div className={panelStyles.twoColumnsPanel}>
      <MainNav className={panelStyles.mainNav} />
      <LeftNav className={panelStyles.leftColumn} />
      <Questions className={panelStyles.mainColumn} />
    </div>
  );
}
