import { createContext, useState } from "react";
import styles from "./ModalContext.module.scss";

export type ModalContextType = {
  showModal: (element: JSX.Element) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType>({
  showModal: () => {},
  closeModal: () => {},
});

export function ModalContextProvider(props: React.PropsWithChildren<unknown>) {
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
  const [element, setElement] = useState<JSX.Element | null>(null);
  const ctx: ModalContextType = {
    showModal: (element: JSX.Element) => {
      setElement(element);
      setShouldShowModal(true);
    },
    closeModal: () => {
      setShouldShowModal(false);
      setElement(null);
    },
  };

  return (
    <ModalContext.Provider value={ctx}>
      <div className={styles.modalContextRoot}>
        {props.children}
        <div
          className={`${styles.modalElementContainer}${
            shouldShowModal ? ` ${styles.active}` : ""
          }`}
        >
          <div className={styles.modalElement}>
            {element}
            <button
              className={styles.closeButton}
              title="Close"
              onClick={() => ctx.closeModal()}
            />
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  );
}
