import { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { ProjectContext } from "../contexts/ProjectContext";
import { TopicsContext } from "../contexts/TopicsContext";
import { IGeneratedTopic, IProject } from "../lib/types";
import styles from "./GeneratedTopics.module.scss";
import topic48 from "../images/topic-fill-gray5-48.svg";
import topic24 from "../images/topic-fill-gray5-24.svg";
import { saveUserTopics } from "../lib/firestore";
import { UserContext } from "../contexts/UserContext";

enum KeepingState {
  Idle,
  Keeping,
  Kept,
}

type DiscardableGeneratedTopic = IGeneratedTopic & { discard: boolean };

export default function GeneratedTopics(props: { className?: string }) {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const { generatedTopics } = useContext(TopicsContext);
  const [topics, setTopics] = useState<DiscardableGeneratedTopic[]>([]);
  const [keeping, setKeeping] = useState<KeepingState>(KeepingState.Idle);

  useEffect(() => {
    if ((generatedTopics?.length ?? 0) > 0) {
      setTopics(generatedTopics.map((t) => ({ ...t, discard: false })));
    }
  }, [generatedTopics]);

  const toggleTopic = useCallback((topic: DiscardableGeneratedTopic) => {
    setTopics((topics) => {
      const index = topics.findIndex((t) => t.topicId === topic.topicId);
      if (index < 0) return topics;
      const newTopics = [...topics];
      newTopics.splice(index, 1, { ...topic, discard: !topic.discard });
      return newTopics;
    });
  }, []);

  const keepTopics = useCallback(() => {
    if (project == null || user == null) return;
    setKeeping(KeepingState.Keeping);
    saveUserTopics(
      project?.id,
      user?.uid,
      topics.filter((t) => !t.discard)
    )
      .then(() => {
        setKeeping(KeepingState.Kept);
        window.setTimeout(() => {
          history.push(`/projects/${project.id}/topics`);
        }, 800);
      })
      .catch(console.error);
  }, [project, user, topics, history]);

  return (
    <>
      <div
        className={`${props.className} ${styles.topics}${
          topics.length === 0 ? ` ${styles.empty}` : ""
        }`}
      >
        <SubNav
          numTopics={topics.filter((t) => !t.discard).length}
          keepingState={keeping}
          keepTopics={keepTopics}
        />
        {topics.length > 0 ? (
          <TopicCards generatedTopics={topics} discardTopic={toggleTopic} />
        ) : (
          <EmptyTopics />
        )}
      </div>
      <div
        className={`loading-layer${
          keeping === KeepingState.Keeping ? " active" : ""
        }`}
      >
        <div className="loading-indicator" />
      </div>
    </>
  );
}

function SubNav(props: {
  numTopics: number;
  keepingState: KeepingState;
  keepTopics: () => void;
}) {
  const additionalButtonClassName =
    props.keepingState === KeepingState.Kept ? ` success` : "";
  return (
    <div className={styles.subnav}>
      <h1 className={styles.heading}>Keep or discard suggested topics</h1>
      <h4 className={styles.subheading}>
        Qually analyzed your data and found these conversation topics. Which
        would you like to keep? Note: you can rename topics later.
      </h4>
      <div className={styles.controls}>
        <button
          className={`button primary ${styles.keepTopicsButton}${additionalButtonClassName}`}
          disabled={props.keepingState === KeepingState.Keeping}
          onClick={props.keepTopics}
        >
          {props.keepingState === KeepingState.Keeping
            ? `Keeping ${props.numTopics} topics...`
            : `Keep ${props.numTopics} topics`}
        </button>
      </div>
    </div>
  );
}

function TopicCards(props: {
  generatedTopics: DiscardableGeneratedTopic[];
  discardTopic: (topic: DiscardableGeneratedTopic) => void;
}) {
  return (
    <div className={styles.topicCards}>
      {props.generatedTopics.map((gtopic) => (
        <TopicCard
          key={gtopic.topicId}
          generatedTopic={gtopic}
          discardTopic={() => props.discardTopic(gtopic)}
        />
      ))}
    </div>
  );
}

function TopicCard(props: {
  generatedTopic: DiscardableGeneratedTopic;
  discardTopic: () => void;
}) {
  const { project } = useContext(ProjectContext);
  const [expandedQuotes, setExpandedQuotes] = useState<boolean>(false);

  return (
    <div
      className={`${styles.topicCard}${
        props.generatedTopic.discard ? ` ${styles.discarded}` : ""
      }`}
    >
      <div className={styles.topicCardContent}>
        <TopicCardHead
          project={project}
          generatedTopic={props.generatedTopic}
          discardTopic={props.discardTopic}
        />
        <SampleQuotes
          top_segments={(props.generatedTopic.top_segments ?? []).slice(0, 2)}
          expanded={expandedQuotes}
          toggleQuotes={() => setExpandedQuotes((e) => !e)}
        />
        <TopicCardFoot
          numSegments={props.generatedTopic.segment_count ?? NaN}
        />
      </div>
      <div className={styles.topicCardLayer} />
      <button className={styles.discardButton} onClick={props.discardTopic} />
    </div>
  );
}

function TopicCardHead(props: {
  project: IProject | null;
  generatedTopic: IGeneratedTopic;
  discardTopic: () => void;
}) {
  return (
    <div className={styles.topicCardHead}>
      <div className={`subhead ${styles.subhead}`}>Keywords</div>
      <div className={styles.topicTags}>
        {props.generatedTopic.keywords.map((keyword) => (
          <span key={keyword} className={`keyword ${styles.topicTag}`}>
            {keyword}
          </span>
        ))}
      </div>
    </div>
  );
}

function SampleQuotes(props: {
  top_segments: { score: number; text: string }[];
  expanded: boolean;
  toggleQuotes: () => void;
}) {
  return (
    <div
      className={`${styles.sampleQuotes}${
        props.expanded ? ` ${styles.expanded}` : ""
      }`}
    >
      <div className={`subhead ${styles.subhead}`}>Sample Quotes</div>
      {props.top_segments.map((segment, i) => (
        <div key={i} className={`text ${styles.sampleQuote}`}>
          &ldquo;{segment.text}&rdquo;
        </div>
      ))}
      <div className={styles.seeMoreLayer}>
        <button
          className={`button secondary ${styles.seeMoreButton}`}
          onClick={props.toggleQuotes}
        >
          See More
        </button>
      </div>
    </div>
  );
}

function TopicCardFoot(props: {
  numSegments: number;
  sentimentScore?: number;
  numInterviews?: number;
}) {
  const { numSegments, sentimentScore, numInterviews } = {
    sentimentScore: NaN,
    numInterviews: NaN,
    ...props,
  };
  return (
    <div className={styles.topicCardFoot}>
      <div>
        <span
          className={`text ${styles.numSegments}`}
        >{`${numSegments} Quotes`}</span>
      </div>
      <div>
        <span className={`text ${styles.sentimentScore}`}>{`${(
          sentimentScore * 100
        ).toFixed(0)}% positive`}</span>
      </div>
      <div>
        <span
          className={`text ${styles.numInterviews}`}
        >{`${numInterviews} people`}</span>
      </div>
    </div>
  );
}

function EmptyTopics() {
  const { project } = useContext(ProjectContext);

  return (
    <div className={styles.emptyTopics}>
      <div className={styles.topicIcons}>
        <img src={topic24} className={`${styles.topic16}`} alt="topic 16" />
        <img src={topic24} className={`${styles.topic24}`} alt="topic 24" />
        <img src={topic48} className={`${styles.topic48}`} alt="topic 48" />
      </div>
      <h2>No Topics Yet</h2>
      <h4>
        Complete the{" "}
        <Link to={`/projects/${project?.id}`} className="link">
          set-up checklist
        </Link>{" "}
        in project settings. Then, you can run Qually’s topic-finding algorithm.
      </h4>
    </div>
  );
}
