import panelStyles from "./Panel.module.scss";
import LeftNav from "../components/LeftNav";
import Transcript from "./Transcript";
import MainNav from "../components/MainNav";
import Interviews from "./Interviews";
import { useContext, useState } from "react";
import { IInterviewDetail } from "../lib/types";
import { InterviewsContext } from "../contexts/InterviewsContext";

export default function InterviewsPanel() {
  const { interviews } = useContext(InterviewsContext);
  const [selectedInterview, setSelectedInterview] = useState<IInterviewDetail | null>(null);

  return (
    <div
      className={
        interviews.length === 0
          ? panelStyles.twoColumnsPanel
          : panelStyles.threeColumnsPanel
      }
    >
      <MainNav className={panelStyles.mainNav} />
      <LeftNav className={panelStyles.leftColumn} />
      <Interviews
        className={
          interviews.length === 0
            ? panelStyles.mainColumn
            : panelStyles.centerColumn
        }
        onSelectInterview={setSelectedInterview}
      />
      {interviews.length === 0 ? null : (
        <Transcript
          className={panelStyles.rightColumn}
          interview={selectedInterview}
        />
      )}
    </div>
  );
}
