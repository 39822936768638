export async function analyzeProject(projectId: string) {
  const url = `https://us-central1-qually-f616e.cloudfunctions.net/analyzeProject?projectid=${projectId}`;
  return fetch(url, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
  });
}
