import { IBoxFolder } from "./types";

const BOX_API_URL_ROOT = "https://api.box.com/2.0";

export async function collectItemsInBoxFolder(
  accessToken: string,
  folder: IBoxFolder
): Promise<IBoxFolder> {
  const res = await fetch(`${BOX_API_URL_ROOT}/folders/${folder.id}/items`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());

  if (typeof res.entries === "undefined") return folder;
  res.entries = res.entries.filter((e: any) => e.type === "file");
  const newFolder = { ...folder, item_collection: res };
  return newFolder;
}
