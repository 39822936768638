import styles from "./Toast.module.scss";

export default function Toast(props: { text: string; show: boolean }) {
  return (
    <div
      className={`text ${styles.toastContainer}${
        props.show ? ` ${styles.visible}` : ""
      }`}
    >
      <div className={styles.toast}>{props.text}</div>
    </div>
  );
}
