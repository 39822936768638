import { useCallback, useContext, useEffect } from "react";
import { BoxAuthContext } from "../contexts/BoxAuthContext";
import { ModalContext } from "../contexts/ModalContext";
import { collectItemsInBoxFolder } from "../lib/boxApi";
import { IBoxFolder } from "../lib/types";
import BoxContentPicker from "./BoxContentPicker";

const ICO_ROOT_FOLDER_ID = process.env.REACT_APP_BOX_ICO_ROOT_FOLDER_ID;

export default function useModalBoxFolderPicker(
  onFolderSelected: (folder: any) => void
) {
  const modal = useContext(ModalContext);
  const boxAuth = useContext(BoxAuthContext);

  const onCancel = useCallback(() => {
    modal.closeModal();
  }, [modal]);

  const onPickFolder = useCallback(
    (accessToken: string) => {
      return (folder: IBoxFolder[]) => {
        collectItemsInBoxFolder(accessToken, folder[0]).then((folder) => {
          onFolderSelected(folder);
          modal.closeModal();
        });
      };
    },
    [onFolderSelected, modal]
  );

  const showModalContentPicker = useCallback(
    (accessToken: string) => {
      window.sessionStorage.removeItem("qually_box_logging_in");
      modal.showModal(
        <BoxContentPicker
          accessToken={accessToken}
          rootFolderId={ICO_ROOT_FOLDER_ID!}
          onCancel={onCancel}
          onPickFolder={onPickFolder(accessToken)}
        />
      );
    },
    [modal, onCancel, onPickFolder]
  );

  const selectBoxFolder = useCallback(() => {
    boxAuth.getAccessToken().then((accessToken) => {
      if (accessToken === null) {
        window.sessionStorage.setItem("qually_box_logging_in", "true");
        boxAuth.onLogin();
      } else {
        showModalContentPicker(accessToken);
      }
    });
  }, [boxAuth, showModalContentPicker]);

  useEffect(() => {
    const wasLoggingIn = window.sessionStorage.getItem("qually_box_logging_in");
    if (wasLoggingIn === "true") {
      boxAuth.getAccessToken().then((accessToken) => {
        if (accessToken !== null) {
          showModalContentPicker(accessToken);
        }
      });
    }
  }, [boxAuth, showModalContentPicker]);

  return selectBoxFolder;
}
