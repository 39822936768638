import { useContext, useState } from "react";
import panelStyles from "./Panel.module.scss";
import Segments from "./Segments";
import LeftNav, { useQueryParams } from "../components/LeftNav";
import Transcript from "./Transcript";
import { ISegment, ITopicFilter } from "../lib/types";
import MainNav from "../components/MainNav";
import { useParams } from "react-router";
import { TopicsContext } from "../contexts/TopicsContext";

function useTopicFilter() {
  const { topicId } = useParams<{ topicId: string }>();
  const { topics } = useContext(TopicsContext);
  const topic = topics.find((t) => t.topicId === topicId);
  const { score } = useQueryParams();
  if (topic == null) return null;
  return { ...topic, score: score } as ITopicFilter;
}

export default function SingleTopicPanel() {
  const [selectedSegment, setSelectedSegment] = useState<ISegment>();
  const topicFilter = useTopicFilter();
  if (topicFilter == null) return null;

  return (
    <div className={panelStyles.threeColumnsPanel}>
      <MainNav className={panelStyles.mainNav} />
      <LeftNav className={panelStyles.leftColumn} />
      <Segments
        className={panelStyles.centerColumn}
        topicFilter={topicFilter}
        onSelectSegment={setSelectedSegment}
      />
      <Transcript
        className={panelStyles.rightColumn}
        selectedSegment={selectedSegment}
      />
    </div>
  );
}
