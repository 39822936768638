import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  cleanUpStaleSegments,
  observeProjectStatus,
} from "../lib/firestore";
import {
  ProjectState,
  projectState,
  Status,
  StatusRecord,
} from "../lib/status";
import { UserContext } from "./UserContext";

export const ProjectStatusContext = createContext<StatusRecord | null>(null);

export function ProjectStatusContextProvider(
  props: React.PropsWithChildren<any>
) {
  const { user } = useContext(UserContext);
  const { projectId } = useParams<{ projectId: string }>();
  const [statusRecord, setStatusRecord] = useState<StatusRecord | null>(null);
  const onSegmentsPopulated = useCallback(() => {
    if (user === null) return;
    cleanUpStaleSegments(projectId, user.uid).catch(console.error);
  }, [user, projectId]);

  useEffect(() => {
    if (projectId == null || user === null) return;
    let unsubscribe: () => void = () =>
      console.error(
        "Unsubscriber function was not registered: ProjectStatusContext"
      );
    observeProjectStatus(projectId, user.uid, (newStatusRecord) => {
      setStatusRecord((sr) => {
        if (
          (sr?.matchQuoteToTopic?.status ?? Status.NotStarted) ===
            Status.InProgress &&
          newStatusRecord.matchQuoteToTopic.status === Status.Complete
        ) {
          onSegmentsPopulated();
        }
        return newStatusRecord;
      });
    }).then((u) => {
      unsubscribe = u;
    });
    return () => {
      unsubscribe();
    };
  }, [user, projectId, onSegmentsPopulated]);

  return (
    <ProjectStatusContext.Provider value={statusRecord}>
      {props.children}
    </ProjectStatusContext.Provider>
  );
}

export function statusRecordLabel(
  statusRecord: StatusRecord | null | undefined
): string {
  const state = projectState(statusRecord);
  switch (state) {
    case ProjectState.UNKNOWN:
      return "...";
    case ProjectState.ERROR:
      return "Error!";
    case ProjectState.CREATED:
      return "Project was created.";
    case ProjectState.UPLOADING_MEDIA:
      return "Uploading media...";
    case ProjectState.TRANSCRIBING:
      return "Transcribing...";
    case ProjectState.IMPORTING_TRANSCRIPTION:
      return "Importing transcriptions...";
    case ProjectState.ANALYZING_SENTIMENT:
      return "Analyzing sentiments...";
    case ProjectState.PROCESSING_INTERVIEW_GUIDE:
      return "Processing the interview guide...";
    case ProjectState.GENERATING_TOPICS:
      return "Generating topics...";
    case ProjectState.MATCHING_QUOTE_TO_TOPICS:
      return "Matching quote to topics...";
    case ProjectState.SORTING_QUOTES:
      return "Sorting quotes...";
    case ProjectState.READY:
      return "Ready.";
  }
}
