import { useCallback, useState, createContext, useEffect } from "react";
import crypto from "crypto";

type BoxAuthContextType = {
  getAccessToken: () => Promise<string | null>;
  onLogin: () => void;
};

const isProduction = process.env.NODE_ENV.toLowerCase() === "production";
const isLive = isProduction && process.env.REACT_APP_HOSTING_CHANNEL === "live";

const oauth2ClientId = isProduction
  ? isLive
    ? process.env.REACT_APP_BOX_OAUTH2_AUTH_CLIENTID_LIVE
    : process.env.REACT_APP_BOX_OAUTH2_AUTH_CLIENTID_PREVIEW
  : process.env.REACT_APP_BOX_OAUTH2_AUTH_CLIENTID_LOCAL;
const oauth2ClientSecret = isProduction
  ? isLive
    ? process.env.REACT_APP_BOX_OAUTH2_AUTH_CLIENTSECRET_LIVE
    : process.env.REACT_APP_BOX_OAUTH2_AUTH_CLIENTSECRET_PREVIEW
  : process.env.REACT_APP_BOX_OAUTH2_AUTH_CLIENTSECRET_LOCAL;

export const BoxAuthContext = createContext<BoxAuthContextType>({
  getAccessToken: () => Promise.reject("BoxAuthContext is not properly set up"),
  onLogin: () => {
    throw new Error("BoxAuthContext is not properly set up");
  },
});

export function BoxAuthContextProvider(
  props: React.PropsWithChildren<unknown>
) {
  const onLogin = useOnLogin();
  const getAccessToken = useAccessToken();
  const ctx = { getAccessToken, onLogin };

  return (
    <BoxAuthContext.Provider value={ctx}>
      {props.children}
    </BoxAuthContext.Provider>
  );
}

function useOnLogin() {
  // TODO use oauth2 state properly to mitigate attacks
  const [oAuth2ClientState] = useState<string>(
    crypto.randomBytes(8).toString("hex")
  );
  const onLogin = useCallback(() => {
    const redirectUri = encodeURIComponent(window.location.href);
    window.location.href = `https://account.box.com/api/oauth2/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${oauth2ClientId}&state=${oAuth2ClientState}`;
  }, [oAuth2ClientState]);
  return onLogin;
}

function useAccessToken() {
  const [currentCode, setCurrentCode] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  const tokenResponseHandler = useCallback((res) => {
    setAccessToken(res.access_token);
    setRefreshToken(res.refresh_token);
    setTimeout(() => {
      setAccessToken(null);
    }, res.expires_in * 1000);
    return res.access_token;
  }, []);

  const tokenErrorHandler = useCallback((e) => {
    console.error(e);
    setAccessToken(null);
    setRefreshToken(null);
  }, []);

  const getAccessToken = useCallback(() => {
    if (accessToken !== null) {
      return Promise.resolve(accessToken);
    } else if (refreshToken !== null) {
      return renewBoxAccessToken(refreshToken)
        .then(tokenResponseHandler)
        .catch(tokenErrorHandler);
    } else {
      return Promise.resolve(null);
    }
  }, [accessToken, refreshToken, tokenResponseHandler, tokenErrorHandler]);

  const code = extractCodeFromQuery(window.location.search);
  useEffect(() => {
    if (code !== null && currentCode !== code) {
      setCurrentCode(code);

      getBoxAccessToken(code)
        .then(tokenResponseHandler)
        .catch(tokenErrorHandler);
    } else {
      window.history.replaceState(
        null,
        "",
        window.location.pathname + window.location.hash
      );
    }
  }, [code, currentCode, tokenResponseHandler, tokenErrorHandler]);

  return getAccessToken;
}

async function getBoxTokenInner(body: string) {
  return fetch("https://api.box.com/oauth2/token", {
    method: "POST",
    body: `${body}&client_id=${oauth2ClientId}&client_secret=${oauth2ClientSecret}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

async function getBoxAccessToken(code: string) {
  return getBoxTokenInner(`grant_type=authorization_code&code=${code}`);
}

async function renewBoxAccessToken(refreshToken: string) {
  return getBoxTokenInner(
    `grant_type=refresh_token&refresh_token=${refreshToken}`
  );
}

function extractCodeFromQuery(query: string): string | null {
  if (query.length > "?code=".length) {
    const c = query
      .split("?")[1]
      .split("&")
      .find((p) => p.startsWith("code="));
    if (c !== undefined) {
      return c.substring("code=".length);
    }
  }
  return null;
}
