import { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import SingleTextInputForm from "../components/SingleTextInputForm";
import { ProjectContext } from "../contexts/ProjectContext";
import { QuestionsContext } from "../contexts/QuestionsContext";
import { IProject, IQuestion } from "../lib/types";
import styles from "./Questions.module.scss";

export default function Questions(props: { className?: string }) {
  const { questions } = useContext(QuestionsContext);

  return (
    <div
      className={`${props.className} ${styles.questions}${
        questions.length === 0 ? ` ${styles.empty}` : ""
      }`}
    >
      <SubNav />
      {questions.length > 0 ? <QuestionCards /> : <EmptyQuestions />}
    </div>
  );
}

function SubNav() {
  return (
    <div className={styles.subnav}>
      <h1 className={styles.heading}>Questions</h1>
      <h4 className={styles.subheading}>
        Qually analyzed your interviews and found these questions
      </h4>
    </div>
  );
}

function QuestionCards() {
  const { questions, updateDisplayName } = useContext(QuestionsContext);

  return (
    <div className={styles.questionCards}>
      {questions.map((question) => (
        <QuestionCard
          key={question.id}
          question={question}
          onUpdateDisplayName={updateDisplayName}
        />
      ))}
    </div>
  );
}

function QuestionCard(props: {
  question: IQuestion;
  onUpdateDisplayName: (question: IQuestion, name: string) => void;
}) {
  const { project } = useContext(ProjectContext);
  const [isRenaming, setRenaming] = useState<boolean>(false);
  const onSaveDisplayName = useCallback(
    (name: string) => {
      props.onUpdateDisplayName(props.question, name);
      setRenaming(false);
    },
    [props]
  );

  return (
    <div
      className={`${styles.questionCard}${
        isRenaming ? ` ${styles.active}` : ""
      }`}
    >
      {isRenaming ? (
        <SingleTextInputForm
          className={styles.renameQuestionForm}
          defaultValue={props.question.displayName ?? props.question.question}
          onCancelForm={() => setRenaming(false)}
          onSaveForm={onSaveDisplayName}
        />
      ) : (
        <QuestionCardHead
          project={project}
          question={props.question}
          onRename={() => setRenaming(true)}
        />
      )}
      <div className={styles.questionCardContent}>
        <ul className={styles.questions}>
          {props.question.questions.map((q, i) => (
            <li key={i}>{q}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function QuestionCardHead(props: {
  project: IProject | null;
  question: IQuestion;
  onRename: () => void;
}) {
  return (
    <div className={styles.questionCardHead}>
      <h2>{props.question.displayName ?? props.question.question}</h2>
      <div className={styles.controls}>
        <button
          className={`linkButton ${styles.renameButton}`}
          onClick={props.onRename}
        >
          Rename
        </button>
      </div>
    </div>
  );
}

function EmptyQuestions() {
  const { project } = useContext(ProjectContext);

  return (
    <div className={styles.emptyQuestions}>
      <h2>No Questions Yet</h2>
      <h4>
        Complete the{" "}
        <Link to={`/projects/${project?.id}`} className="link">
          set-up checklist
        </Link>{" "}
        in project settings. Then, you can run Qually's question-finding
        algorithm.
      </h4>
    </div>
  );
}
