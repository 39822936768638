// Taken mostly from https://stackoverflow.com/a/51086893

export default class Mutex {
  private _lock = Promise.resolve();
  acquire = () => {
    let resolverFunc: () => void;
    const p = new Promise<void>((resolve) => {
      resolverFunc = () => resolve();
    });
    const release = this._lock.then(() => resolverFunc);
    this._lock = p;
    return release;
  };
}
