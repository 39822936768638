import firebase from "firebase";

export interface IUser {
  uid: string;
  admin: boolean;
  projectsTouched: string[];
}

export interface IBoxItem {
  id: string;
  name: string;
  type: string;
}

export interface IBoxFolder extends IBoxItem {
  item_collection: {
    entries: IBoxItem[] | string[];
    limit: number;
    offset: number;
    total_count: number;
  };
}

export interface IProjectWithRequiredFields {
  doneSetup: boolean;
  modelGenerationCode: number;
  modelGenerationMessage: string;
  neverDelete: boolean;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  displayName: string;
}

export interface IInterviewTheme {
  theme: string;
  description?: string;
  questions: string[];
}

export interface IProject extends IProjectWithRequiredFields {
  id: string;
  topics_count?: number;
  interviews_count?: number;
  boxFolder?: IBoxFolder;
  interviewGuide?: IInterviewTheme[];
  authorizedUsers: string[];
}

export interface IGeneratedTopic {
  cohort: string;
  frequency: number;
  id: string;
  keyword_scores: { [key: string]: number };
  keywords: string[];
  topic_index: number;
  topic_model_num: number;
  segment_count?: number;
  top_segments?: { score: number, text: string }[]
  topicId: string;
}

export interface IUserTopic {
  topicId: string;
  name: string;
}

export interface ITopic extends IGeneratedTopic, IUserTopic {}

export const EMPTY_TOPIC: ITopic = {
  topicId: "empty-1",
  cohort: "",
  frequency: 0,
  id: "-1",
  keyword_scores: {},
  keywords: [],
  topic_index: -1,
  topic_model_num: -1,
  name: "Uncategorized",
  segment_count: 0,
};

export interface IInterview {
  id: string;
  ivid: string; // id string that lives in each object in firestore
  displayName: string;
  transcript: ISegmentSummary[];
}

export interface IInterviewView {
  displayName: string;
  tags: { [tag: string]: boolean };
}

export interface IInterviewDetail extends IInterview, IInterviewView {}

export interface ISegmentSummary {
  interviewer: boolean;
  text: string;
}

export interface ISegmentDetail {
  metaId: string;
  id: string;
  interview: string;
  indexInInterview: number;
  topicIds: string[];
  score: number;
  user_topicIds?: string[];
}

export type ISegment = ISegmentSummary & ISegmentDetail;

export type ISegmentWithDisplayName = ISegment & { displayName: string }

export type ISegmentWithFav = ISegmentWithDisplayName & { fav: boolean };

export interface ITopicFilter extends ITopic {
  score?: number;
}

export const INTERVIEWER: IInterviewDetail = {
  id: "interviewer",
  ivid: "interviewer",
  displayName: "Interviewer",
  transcript: [],
  tags: {},
};

export type InterviewsFilterType = {
  op: "include" | "exclude";
  interviews: IInterview[];
};

export interface IQuestion {
  id: string;
  question: string;
  questions: string[];
  order: number;
}

export interface IQuestion {
  id: string;
  question: string;
  displayName?: string;
  questions: string[];
  order: number;
}