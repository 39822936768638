// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDDdWVIzMmbotlFxSE5sUULYW68_EZ1Vu0",
  authDomain: "qually-f616e.firebaseapp.com",
  projectId: "qually-f616e",
  storageBucket: "qually-f616e.appspot.com",
  messagingSenderId: "939774424729",
  appId: "1:939774424729:web:85203f5d9f449bb5196a08",
  measurementId: "G-RT1BSHGTEV",
  databaseURL: "",
};
